export const WeightUnitStrs = ["Lbs", "Kg", "Stones"];
export const HeightUnitStrs = ["Ft in", "Cm", "Inch"];
export const WeekStartStrs = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const SecondaryMetricStrsAll = ["None", "Calories", "Macros"];
export const SecondaryMetricStrsPart = ["None", "Macros"];
export const ExtraAllowanceStrs = [
  "Weekly First",
  "Activity First",
  "Manually Redeem",
];
export const ActivityAllowanceStrs = ["Weekly", "Daily", "Do Not Use"];

export const TrackerType = {
  BREAKFAST: 0,
  LUNCH: 1,
  DINNER: 2,
  SNACK: 3,
  ACTIVITY: 4,
  NOTE: 5,
  WEIGHT: 6,
  REDEEMWEEKLY: 7,
  REDEEMACTIVITY: 8,
  FITBIT: 9,
  HEALTHKIT: 10,
  CHECKS: 11,
};

export const LossPlan = {
  CONQUER_CRAVINGS: 0,
  CARB_CONSCIOUS: 1,
  CALORIE_COMMAND: 2,
  SUGAR_SMART: 3,
  BETTER_BALANCE: 4,
  KEEPING_KETO: 5,
};

export const LossPlanNames = [
  "Conquer Cravings",
  "Carb Conscious",
  "Calorie Command",
  "Sugar Smart",
  "Better Balance",
  "Keeping Keto",
];

export const Gender = {
  FEMALE: 0,
  MALE: 1,
  NURSING_FULL_TIME: 2,
  NURSING_PART_TIME: 3,
};

export const GenderNames = [
  "Female",
  "Male",
  "Female - Nursing (Full-time)",
  "Female - Nursing (Part-time)",
];

export const FitnessGoal = {
  MAINTAIN: 0,
  LOSE_HALF_LBS_PER_WEEK: 1,
  LOSE_ONE_LBS_PER_WEEK: 2,
  LOSE_ONE_AND_HALF_LBS_PER_WEEK: 3,
  LOSE_TWO_LBS_PER_WEEK: 4,
  GAIN_HALF_PER_WEEK: 5,
  GAIN_ONE_PER_WEEK: 6,
};

export const FitnessGoalBitesNames = ["Maintain Weight", "Lose Weight"];
export const FitnessGoalCaloriesKgNames = [
  "Maintain Weight",
  "Lose 0.25 kg per week",
  "Lose 0.50 kg per week",
  "Lose 0.75 kg per week",
  "Lose 1.00 kg per week",
  "Gain 0.25 kg per week",
  "Gain 0.5 kg per week",
];
export const FitnessGoalCaloriesLbsNames = [
  "Maintain Weight",
  "Lose 0.5 lbs per week",
  "Lose 1 lb per week",
  "Lose 1.5 lbs per week",
  "Lose 2 lbs per week",
  "Gain 0.5 lbs per week",
  "Gain 1 lb per week",
];

export const StepTracking = {
  FITBIT: 0,
  HEALTH_KIT: 1,
};

export const ActivityAllowance = {
  USE_WEEKLY: 0,
  USE_DAILY: 1,
  NOT_USED: 2,
};

export const ExtraAllowance = {
  USE_WEEKLY_FIRST: 0,
  USE_ACTIVITY_FIRST: 1,
  MANUAL: 2,
};

export const SecondaryMetric = {
  NONE: 0,
  CALORIES: 1,
  MACROS: 2,
};

export const WeightUnit = {
  POUNDS: 0,
  KILOGRAMS: 1,
  STONES: 2,
};

export const HeightUnit = {
  FEET_AND_INCHES: 0,
  CENTIMETERS: 1,
  INCHES: 2,
};

export const MilestoneType = {
  WEIGHT_NONE: 0,
  WEIGHT_FIRST_FIVE: 1,
  WEIGHT_FIVE: 2,
  WEIGHT_TEN: 3,
  WEIGHT_FIVE_PERCENT: 4,
  WEIGHT_TEN_PERCENT: 5,
  WEIGHT_FIFTY: 6,
  WEIGHT_GOAL: 7,
};

export const DateRangeType = {
  TYPE_WEEK: 0,
  TYPE_MONTH: 1,
  TYPE_QUARTER: 2,
  TYPE_YEAR: 3,
  TYPE_ALL: 4,
  TYPE_ALL_YEAR: 30,
  TYPE_ALL_QUARTER: 20,
};

export const SourceType = {
  CUSTOM: 0,
  LOCAL: 1,
  DAILYBURN: 2,
  ONLINE: 3,
  TRASH: 4,
  V5: 5,
};
export const FoodType = {
  QUICKADD: 0, // ignore for v6.0
  CUSTOM: 1,
  FOOD: 2,
  POCKET: 3,
  SNACK: 4,
  BEER: 5,
  RESTAURANT: 6,
  NUTRITIONIX: 7,
  RECIPE: 8,
};
export const WeightLossPlan = {
  POINTS: 0,
  POINTSPLUS: 1,
  CALORIES: 2,
  SMARTPOINTS: 3,
  FLEX: 4,
};

export const HeightCategory = {
  UNDER_FIVEONE: 0,
  FIVEONE_TO_FIVETEN: 1,
  OVER_FIVETEN: 2,
};

export const ActivityLevel = {
  SEDENTARY: 0,
  LIGHT: 1,
  MODERATE: 2,
  HEAVY: 3,
  EXTREME: 4,
};

export const ActivityLevelNames = [
  "Sedentary",
  "Light",
  "Moderate",
  "Heavy",
  "Extreme",
];

export const FeatureType = {
  FITBIT: 0,
  ONLINE_FOODS: 1,
  BRANDS: 2,
  RECIPES: 3,
  COMMUNITY: 4,
  RESTAURANT: 5,
};

export const MealTypeStr = ["BreakFast", "Lunch", "Dinner", "Snack"];

export const MealTypeIcon = ["sun", "brightness", "moon-stars", "apple"];

export const DifficultyStr = ["Easy", "Moderate", "Hard"];

export const IntensityType = {
  LOW: 0,
  MEDIUM: 1,
  HIGH: 2,
};

export const IntensityTypeStr = ["Low", "Medium", "High"];

export const PaymentMethod = {
  UNKONW: -1,
  BACKEND: 0,
  APPLE: 1,
  ANDROID: 2,
  STRIPE: 3,
};
