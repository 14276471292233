import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { Container, CircularProgress, makeStyles } from "@material-ui/core";
import { appStore } from "models";
import { observer } from "mobx-react";
import { getUserId } from "helpers/authstorage";
import RenderInBody from "components/RenderInBody";
import { appInitGetUserInfo } from "models/actions";

interface Props {
  component: React.FC;
  path?: string;
  unguarded?: boolean;
  className?: string;
  exact?: boolean;
}

const useStyles = makeStyles((theme) => ({
  fixed: {
    height: "100%",
    width: "100%",
    top: 0,
    position: "fixed",
    zIndex: 1300,
    maxWidth: "none",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

type CanAccess = boolean;

const GuardedRoute: React.FC<Props> = ({
  component: Component,
  unguarded,
  className,
  exact = true,
  path,
  ...rest
}) => {
  const location = useLocation();
  const classes = useStyles();
  let canAccess: CanAccess;
  if (!unguarded && !appStore.isAuthencated) {
    const userId = getUserId();
    if (userId) {
      appStore.setAppLoading(true);
      appInitGetUserInfo(userId, false)
        .catch(() => {
          canAccess = false;
        })
        .finally(() => {
          appStore.setAppLoading(false);
        });
    } else {
      canAccess = false;
    }
  } else {
    canAccess = true;
  }

  if (
    appStore.isAuthencated &&
    ["/choose-plan", "/setup"].indexOf(location.pathname) === -1
  ) {
    const r = sessionStorage.getItem("r");
    if (r) {
      sessionStorage.removeItem("r");
      return <Redirect to={r} />;
    }
  }

  if (appStore.isAppLoading) {
    return (
      <RenderInBody>
        <Container
          className={`${classes.fixed} ${className}`}
          maxWidth="sm"
          fixed
        >
          <CircularProgress style={{ color: "#7ED321" }} />
        </Container>
      </RenderInBody>
    );
  }

  if (canAccess) {
    return (
      <Route
        exact={exact}
        key={path}
        path={path}
        {...rest}
        render={(matchProps: any) => <Component {...matchProps} />}
      ></Route>
    );
  }

  return <Redirect exact from="/" to="/login" />;
};

export default observer(GuardedRoute);
