import React from "react";
import { makeStyles, Theme, InputAdornment } from "@material-ui/core";
import AdornmentInput from "components/AdornmentInput";
import MySelect from "components/MySelect";
import { map, lowerCase, upperFirst, get, find } from "lodash-es";
import { ActivityLevel, HeightUnit, WeightUnit } from "config/enum";
import NumberFormat from "react-number-format";
import { Controller, useFormContext } from "react-hook-form";
import SimpleMenu from "components/SimpleMenu";
import { ControledDatePicker } from "components/FormHelper";
import { weigthUnits, heightUnits } from "config/unit";
import {
  StonesFormatter,
  StonesRemoveFormatting,
  FeetInchesFormatter,
  FeetInchesRemoveFormatting,
} from "helpers/format";
import { genderOptions } from "views/EditProfile/SelectGender";

type Props = {
  onUnitChange: (uintType: string, number: number) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 10,
  },
  input: {
    margin: "0px 0px 10px 0px",
  },
  adornment: {
    borderRadius: 22,
    border: "1px solid #BFC6D5",
    height: 24,
    width: 44,
    textAlign: "center",
    fontSize: 14,
    lineHeight: 1.71,
    padding: "0px 10px",
    color: "#32355580",
    cursor: "pointer",
  },
}));

/* eslint-disable @typescript-eslint/ban-types */
const mixErrorProps =
  <P extends object>(ComponentT: React.FC<P & {}>) =>
  (props: any) => {
    const errorProps = {
      error: !!get(props.errors, props.name),
      helperText: get(props.errors, `${props.name}.message`),
    };
    return <ComponentT {...props} {...errorProps}></ComponentT>;
  };

const NumberFormatU = React.memo(NumberFormat);

const NumberFormatWithError = mixErrorProps(
  NumberFormatU as unknown as React.FC,
);

const PersonalDetail: React.FC<Props> = (props) => {
  const { onUnitChange } = props;
  const classes = useStyles();
  const { control, errors, setError, watch, clearError } = useFormContext();
  const { weightUnit, heightUnit } = watch();
  const heightUnitFormat =
    heightUnit === HeightUnit.FEET_AND_INCHES ? FeetInchesFormatter : null;
  const heightUnitReoveFormatting =
    heightUnit === HeightUnit.FEET_AND_INCHES
      ? FeetInchesRemoveFormatting
      : null;
  const weightUnitFormat =
    weightUnit === WeightUnit.STONES ? StonesFormatter : null;
  const weightUnitReoveFormatting =
    weightUnit === WeightUnit.STONES ? StonesRemoveFormatting : null;
  const weightUnitPicker = (
    <Controller
      name="weightUnit"
      as={
        <SimpleMenu
          options={weigthUnits}
          onValueChange={(value) => onUnitChange("weightUnit", value)}
          anchor={(props) => (
            <div className={classes.adornment}>
              {get(find(weigthUnits, { value: weightUnit }), "text")}
            </div>
          )}
        ></SimpleMenu>
      }
    ></Controller>
  );
  const heightUnitPicker = (
    <Controller
      name="heightUnit"
      as={
        <SimpleMenu
          options={heightUnits}
          onValueChange={(value) => onUnitChange("heightUnit", value)}
          anchor={(props) => (
            <div className={classes.adornment}>
              {get(find(heightUnits, { value: heightUnit }), "text")}
            </div>
          )}
        ></SimpleMenu>
      }
    ></Controller>
  );

  return (
    <div className={classes.root}>
      <Controller
        as={ControledDatePicker}
        control={control}
        name="birthDate"
        label="Date of Birth"
        errors={errors}
        setError={setError}
        margin="normal"
        clearError={clearError}
        className={classes.input}
      />
      <Controller
        as={mixErrorProps(MySelect)}
        control={control}
        name="gender"
        label="Gender"
        margin="normal"
        options={genderOptions}
        className={classes.input}
        fullWidth
        errors={errors}
      />
      <Controller
        as={NumberFormatWithError}
        name="height"
        control={control}
        customInput={AdornmentInput}
        isNumericString
        format={heightUnitFormat}
        removeFormatting={heightUnitReoveFormatting}
        className={classes.input}
        margin="normal"
        fullWidth
        id="height"
        label="Your Height"
        endAdornment={
          <InputAdornment position="end">{heightUnitPicker}</InputAdornment>
        }
        errors={errors}
      />
      <Controller
        as={NumberFormatWithError}
        name="startWeight"
        control={control}
        customInput={AdornmentInput}
        format={weightUnitFormat}
        removeFormatting={weightUnitReoveFormatting}
        isNumericString //set to pass whole string to formatter function
        className={classes.input}
        margin="normal"
        fullWidth
        id="weigth"
        label="Your Weight"
        endAdornment={
          <InputAdornment position="end">{weightUnitPicker}</InputAdornment>
        }
        errors={errors}
      />
      <Controller
        as={NumberFormatWithError}
        name="goalWeight"
        control={control}
        customInput={AdornmentInput}
        mask=" "
        format={weightUnitFormat}
        removeFormatting={weightUnitReoveFormatting}
        isNumericString
        className={classes.input}
        margin="normal"
        fullWidth
        id="goalweight"
        label="Goal Weight"
        endAdornment={
          <InputAdornment position="end">{weightUnitPicker}</InputAdornment>
        }
        errors={errors}
      />
      <Controller
        as={mixErrorProps(MySelect)}
        control={control}
        name="activityLevel"
        label="Activity Level"
        margin="normal"
        options={map(ActivityLevel, (value, key) => ({
          text: upperFirst(lowerCase(key)),
          value,
        }))}
        className={classes.input}
        fullWidth
        errors={errors}
      />
    </div>
  );
};

export default PersonalDetail;
