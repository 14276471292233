export default {
  root: {
    textTransform: "none",
    boxShadow: "none",
  },
  colorSecondary: {
    backgroundColor: "transparent",
  },
  colorPrimary: {
    backgroundColor: "white",
  },
};
