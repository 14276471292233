import { COMMISSION_JUNCTION_ID } from "config/appkey";
import { appStore } from "models";
import { sha256 } from "js-sha256";
import { get } from "lodash-es";
import Cookies from "js-cookie";
import { getCJCookie, createCJServerPostback } from "api/Tracking";

const COMMISSION_JUNCTION_COOKIE_NAME = "cje";
const COMMISSION_JUNCTION_SCRIPT_URL =
  "https://www.mczbf.com/tags/11165/tag.js";
const COMMISSION_JUNCTION_SCRIPT_ID = "cjapitag";
const COMMISSION_JUNCTION_USER_SIGNUP_TRACKER_ID = "425172";
const COMMISSION_JUNCTION_USER_UPGRADE_TRACKER_ID = "425173";

class CommissionJunction {
  static cjScript: HTMLScriptElement;

  static logPage = (actionTrackerId = null) => {
    // check for cookie
    const cjCookie = Cookies.get(COMMISSION_JUNCTION_COOKIE_NAME);
    if (!cjCookie) {
      return;
    }

    // set cj data on window object
    if (!(window as any).cj) {
      (window as any).cj = {};
    }

    (window as any).cj.sitePage = {
      enterpriseId: COMMISSION_JUNCTION_ID,
      userId: get(appStore.currentUser, "id", ""),
      emailHash: sha256(get(appStore.currentUser, "email", "")),
      referringChannel: "Direct_Navigation",
    };

    if (actionTrackerId === COMMISSION_JUNCTION_USER_SIGNUP_TRACKER_ID) {
      const leadId = "signup-" + get(appStore.currentUser, "id", "");
      const amount = 0;

      // user signup
      (window as any).cj.order = {
        enterpriseId: COMMISSION_JUNCTION_ID,
        pageType: "conversionConfirmation",
        userId: get(appStore.currentUser, "id", ""),
        emailHash: sha256(get(appStore.currentUser, "email", "")),
        orderId: leadId,
        actionTrackerId: actionTrackerId,
        currency: "USD",
        amount: amount,
        cjeventOrder: cjCookie,
        items: [
          { unitPrice: amount, itemId: "signup", quantity: "1", discount: "0" },
        ],
      };

      createCJServerPostback(actionTrackerId, cjCookie, leadId, amount);
    } else if (
      actionTrackerId === COMMISSION_JUNCTION_USER_UPGRADE_TRACKER_ID
    ) {
      // user upgrade
      const orderId = "upgrade-" + get(appStore.currentUser, "id", "");
      const amount = get(appStore.activeStripePlan, "amount", 0) / 100;

      (window as any).cj.order = {
        enterpriseId: COMMISSION_JUNCTION_ID,
        pageType: "conversionConfirmation",
        userId: get(appStore.currentUser, "id", ""),
        emailHash: sha256(get(appStore.currentUser, "email", "")),
        orderId: orderId,
        actionTrackerId: actionTrackerId,
        currency: "USD",
        amount: amount,
        cjeventOrder: cjCookie,
        items: [
          {
            unitPrice: amount,
            itemId: "upgrade",
            quantity: "1",
            discount: "0",
          },
        ],
      };

      createCJServerPostback(actionTrackerId, cjCookie, orderId, amount);
    } else {
      (window as any).cj.order = {};
    }

    // inject Commission Junction javascript on page
    // note: this is now not being used, all conversion confirmations are done server-to-server
    // CommissionJunction.useScript();
  };

  static logPageAndSetCookie = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cjEvent = urlParams.get("cjevent");

    if (cjEvent) {
      getCJCookie(cjEvent).then((resp) => {
        CommissionJunction.logPage();
      });
    } else {
      CommissionJunction.logPage();
    }
  };

  static useScript = () => {
    // check for cookie
    if (!Cookies.get(COMMISSION_JUNCTION_COOKIE_NAME)) {
      return;
    }

    // create script tag for cj javascript
    const placement = document.querySelector("body");
    const script = document.createElement("script");

    if (placement && CommissionJunction.cjScript) {
      placement.removeChild(CommissionJunction.cjScript);
    }

    script.src = COMMISSION_JUNCTION_SCRIPT_URL;
    script.id = COMMISSION_JUNCTION_SCRIPT_ID;
    script.async = true;

    placement.appendChild(script);
    CommissionJunction.cjScript = script;

    return () => {
      placement.removeChild(script);
    };
  };
}

export default CommissionJunction;
