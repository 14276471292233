import React from "react";
import { map } from "lodash-es";
import IconCheck from "icons/feature/check";
import IconCross from "icons/feature/cross";
import { Button } from "@material-ui/core";

interface Props {
  handleClickUpgrade?: () => void;
}

const featuresComparison = [
  { name: "Food & Activity Tracking", byBasic: true, byPro: true },
  { name: "Macronutrient Tracking", byBasic: false, byPro: true },
  { name: "Create & Browse Recipes", byBasic: false, byPro: true },
  { name: "1M+ Foods & Brands", byBasic: false, byPro: true },
  { name: "Premium Weight Loss Plans", byBasic: false, byPro: true },
  { name: "Fitbit Activity Tracking", byBasic: false, byPro: true },
];

const ProFeatureTable = ({ handleClickUpgrade }: Props) => {
  return (
    <table className="pro-basic-compare-table">
      <thead>
        <tr>
          <th className="features">
            <div className="features-title">
              <span className="what-you-get">What you get</span>
            </div>
          </th>
          <th className="basic">Basic</th>
          <th className="pro">PRO</th>
        </tr>
      </thead>
      <tbody>
        {map(featuresComparison, ({ name, byBasic, byPro }, index) => (
          <tr key={name}>
            <td>
              <div className="features-title">
                <img
                  className="feature-title-icon"
                  src={`images/feature-icons/feature-${index}.svg`}
                  alt=""
                />
                <div className="feature-title-name">{name}</div>
              </div>
            </td>
            <td>
              {byBasic ? (
                <IconCheck className="feature-check"></IconCheck>
              ) : (
                <IconCross className="feature-cross"></IconCross>
              )}
            </td>
            <td>
              {byPro ? (
                <IconCheck className="feature-check"></IconCheck>
              ) : (
                <IconCross className="feature-cross"></IconCross>
              )}
            </td>
          </tr>
        ))}
        {!!handleClickUpgrade && (
          <tr>
            <td></td>
            <td>
              <div className="table-btn-selected">Selected</div>
            </td>
            <td>
              <Button
                onClick={handleClickUpgrade}
                className="btn-red table-btn-upgrade"
                variant="contained"
                color="primary"
              >
                Upgrade
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ProFeatureTable;
