import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Button,
} from "@material-ui/core";
import Male from "icons/male";
import Female from "icons/female";
import clsx from "clsx";
import { Gender } from "config/enum";
import { get } from "lodash-es";

type Props = {
  id: string;
  name: string;
  label?: string;
  errors?: any;
  setError?: any;
  shrink?: boolean;
  className?: string;
  inputClassName?: string;
  margin?: "none" | "dense" | "normal";
  value?: number;
  onChange?: (value: number) => void;
};

export const genderOptions = [
  { text: "Male", value: Gender.MALE },
  { text: "Female", value: Gender.FEMALE },
  { text: "Female - Full-time Nursing", value: Gender.NURSING_FULL_TIME },
  { text: "Female - Part-time Nursing ", value: Gender.NURSING_PART_TIME },
];

const SelectGender: React.FC<Props> = ({
  id,
  label,
  errors,
  name,
  setError,
  value,
  onChange,
}) => {
  const error = !!errors[name];
  const helperText = get(errors, `${name}.message`);
  const handleClick = (gender: number) => () => {
    if (onChange) {
      onChange(gender);
    }
  };
  return (
    <FormControl variant="outlined" fullWidth>
      {label && (
        <InputLabel shrink htmlFor={id}>
          {label}
        </InputLabel>
      )}
      <div className="input-row" id={id}>
        <Button
          className={clsx("input-button", { selected: value === Gender.MALE })}
          onClick={handleClick(Gender.MALE)}
          variant="contained"
          startIcon={<Male />}
        >
          Male
        </Button>
        <Button
          className={clsx("input-button", {
            selected: value === Gender.FEMALE,
          })}
          onClick={handleClick(Gender.FEMALE)}
          variant="contained"
          startIcon={<Female />}
        >
          Female
        </Button>
      </div>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectGender;
