import { observable, computed, action } from "mobx";
import { User, Progress } from "interfaces/user.interface";
import { forEach, sortBy, last, get, find } from "lodash-es";
import { clearUserInfo } from "helpers/authstorage";
import { differenceInYears } from "date-fns";
import { SignUpInput } from "api/Auth";
import { inchesToUnit, lbsToUnit } from "helpers/calculate";
import { trackUserProfile } from "helpers/appTrack";
import { StripePlan, Promo } from "interfaces/plan.interface";
import { FALLBACK_STRIPE_PRODUCT_ID } from "config";
import { addMinutes, isAfter } from "date-fns";
import { removePromoCode } from "api/Auth";

export class AppStore {
  @computed
  get isAuthencated() {
    return !!this.currentUser.authId;
  }
  @computed
  get alreadySetup() {
    return !!this.currentUser.birthDate;
  }
  @computed
  get alreadyChoosePlan() {
    return !!this.currentUser.authId;
  }
  @observable currentUser: User = {};
  @action setCurrentUser(data: any, needTrack: boolean) {
    this.currentUser = mapUserFields(data);
    needTrack && trackUserProfile(this.currentUser);
  }
  @action clearCurrentUser() {
    this.currentUser = {};
  }
  @action logout() {
    if (appStore.currentUser.isProUser || !this.promo) {
      this.removePromo();
      clearUserInfo();
      this.setCurrentUser({}, false);
    } else {
      removePromoCode(this.promo.code).finally(() => {
        this.removePromo();
        clearUserInfo();
        this.setCurrentUser({}, false);
      });
    }
  }

  @observable signUpInfo: SignUpInput = {
    email: "",
  };
  @action clearSignUpInfo() {
    this.signUpInfo = {
      email: "",
    };
  }

  @observable productId = "";
  @observable sourceFrom = "";

  @observable stripePlans: StripePlan[] = [];
  @action setStripePlans = (plans) => {
    this.stripePlans = plans;
  };

  @observable promo: Promo;
  @observable stripeSubscriptionExpired: boolean;

  @action setPromo = (promo) => {
    this.promo = promo;
    sessionStorage.setItem(
      "promoValidUntil",
      String(addMinutes(new Date(), 20)),
    );
    sessionStorage.removeItem("clearPromo");
  };

  @action removePromo() {
    if (this.promo && !this.currentUser.isProUser) {
      removePromoCode(this.promo.code);
    }

    this.promo = null;
    sessionStorage.setItem("clearPromo", String(true));
    sessionStorage.removeItem("productId");
    sessionStorage.removeItem("promoValidUntil");
  }

  @action currentPromoCode() {
    this.checkPromoTimeout();
    return get(this.promo, "code", null);
  }

  @action currentPromoSKU() {
    this.checkPromoTimeout();
    return get(this.promo, "sku", null);
  }

  @action checkPromoTimeout() {
    const promoValidUntil = sessionStorage.getItem("promoValidUntil");
    const promoValidUntilDate = new Date(promoValidUntil);
    if (
      this.promo &&
      promoValidUntil &&
      isAfter(new Date(), promoValidUntilDate)
    ) {
      this.removePromo();
    }
  }

  @computed
  get activeStripePlan() {
    const deepLinkProductId = sessionStorage.getItem("productId");

    return (
      find(
        this.stripePlans,
        (plan) =>
          get(plan, "metadata.productid") ===
          (this.currentPromoSKU() ||
            deepLinkProductId ||
            this.productId ||
            FALLBACK_STRIPE_PRODUCT_ID),
      ) || this.stripePlans[0]
    );
  }

  @observable isAppLoading = false;
  @action setAppLoading = (flag) => {
    this.isAppLoading = flag;
  };

  @observable checkoutFormError: {
    code?: number | string;
    message?: string;
    name?: string;
  } = {};
  @action setCheckoutFormError = (plan) => {
    this.checkoutFormError = plan;
  };
}

export const mapUserFields = (data: any) => {
  forEach(dataTansformMap, ([fn, refKey]: any[], key) => {
    data[key] = fn(data[refKey || key], data);
  });
  return data;
};

export const cleanFields = (data: any) => {
  const cleanData = { ...data };
  delete cleanData.age;
  delete cleanData.recentWeight;
  delete cleanData.height;
  delete cleanData.startWeight;
  delete cleanData.goalWeight;
  return cleanData;
};

const dataTansformMap = {
  gender: [Number],
  lossPlan: [Number],
  weightUnit: [Number],
  goalWeightLbs: [Number],
  startWeightLbs: [Number],
  heightUnit: [Number],
  heightInch: [Number],
  fitnessGoal: [Number],
  fatAllowancePercent: [Number],
  carbsAllowancePercent: [Number],
  proteinAllowancePercent: [Number],
  activityLevel: [Number],
  secondaryMetric: [Number],

  // computed temp fields
  age: [
    (birthDate: string) => {
      return differenceInYears(new Date(), new Date(birthDate!));
    },
    "birthDate",
  ],
  firstName: [(name: string) => (name && name.split(" ")[0]) || "", "name"],
  lastName: [(name: string) => (name && name.split(" ")[1]) || "", "name"],
  recentWeight: [
    (progress: Progress[], data: any) => {
      const progressLast = last(
        sortBy(progress, (item) => +new Date(item.trackerDate)),
      );
      return Number(
        progressLast ? progressLast.weightLbs : data.startWeightLbs,
      );
    },
    "progress",
  ],
  height: [(_, data) => inchesToUnit(data.heightInch, data.heightUnit, 1)],
  startWeight: [(_, data) => lbsToUnit(data.startWeightLbs, data.heightUnit)],
  goalWeight: [(_, data) => lbsToUnit(data.goalWeightLbs, data.heightUnit)],
  isProUser: [(_, data) => get(data, "subscription.pro")],
  isPurchasedApp: [
    (_, data) => +new Date(get(data, "dateCreated")) < 1519315200,
  ],
};

export const appStore = new AppStore();
