import http from "./Http";

export const getCJCookie = (cjevent) => {
  return http
    .get("web/commission-junction", {
      params: { cjevent: cjevent },
      withCredentials: true,
    })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    });
};

export const createCJServerPostback = (
  action_id,
  cjevent,
  order_id,
  amount,
) => {
  return http
    .post("web/commission-junction", {
      action_id,
      cjevent,
      order_id,
      amount,
    })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    });
};
