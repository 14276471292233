/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import LoadingButton from "components/LoadingButton";
import IconClose from "@material-ui/icons/Close";
import ProBadge from "icons/pro-badge";
import IconCheck from "icons/feature/check";
import Appboy from "appboy-web-sdk";
import Amplitude from "amplitude-js";
import { IconButton } from "@material-ui/core";
import { observer } from "mobx-react";
import { appStore } from "models";
import { getPriceString, safeDateFormat } from "helpers/format";
import { get } from "lodash-es";
import CommissionJunction from "helpers/commissionJunction";
import FacebookCAPI from "helpers/facebookCAPI";
import "./index.scss";
import GoogleGA from "helpers/googleGA";

const SubscribeSuccessModal = ({ handleCancel, handleConfirm }) => {
  const currentUser = appStore.currentUser;
  const subscriptionExpirationDate = get(
    currentUser,
    "subscription.subscriptionExpirationDate",
  );

  // get purchase details for tracking
  const productId = get(appStore.activeStripePlan, "metadata.productid", "");
  const planName = get(appStore.activeStripePlan, "metadata.planname", "");
  const amount = get(appStore.activeStripePlan, "amount", 0) / 100;
  const currency = get(appStore.activeStripePlan, "currency", "");
  const code = appStore.currentPromoCode();

  // log purchase to braze
  Appboy.logPurchase(productId, amount, currency, 1, {
    planname: planName,
    "Promo Code": code,
  });

  // log purchase to amplitude
  const eventProperties = {
    currency: currency,
    planname: planName,
    "Promo Code": code,
  };

  const revenue = new Amplitude.Revenue()
    .setProductId(productId)
    .setPrice(amount)
    .setQuantity(1)
    .setRevenueType("income")
    .setEventProperties(eventProperties);
  Amplitude.logRevenueV2(revenue);

  Amplitude.logEvent("Purchased", {
    Price: amount,
    "Product ID": productId,
    "Promo Code": code,
    Source: "web",
  });

  // log purchase to FB
  FacebookCAPI.logEvent('purchase', {
    currency: currency, 
    value: amount
  });
  GoogleGA.logEvent('purchase');
  GoogleGA.logPurchase(currency, amount);
  CommissionJunction.logPage("425173");

  return (
    <div className="modal-content sub-success-modal-content">
      <IconButton className="btn-close-modal" onClick={handleCancel}>
        <IconClose></IconClose>
      </IconButton>
      <div className="modal-title">Congratulations!</div>
      <div className="modal-sub-title">
        You have successfully completed your purchase.
      </div>
      <div className="modal-info-block your-plan">
        <div className="your-plan-box">
          <div className="plan-title">
            {"Healthi PRO"}
          </div>
          <div className="plan-price">
            {getPriceString(appStore.activeStripePlan)}
            <IconCheck className="success-check"></IconCheck>
          </div>
          <div className="plan-expires">
            {`Your subscription is active and your next renewal will be on ${safeDateFormat(
              subscriptionExpirationDate,
              "MMM do, yyyy",
            )}.`}
          </div>
          <ProBadge className="plan-bage-pro" />
        </div>
      </div>
      <LoadingButton
        className="btn-red btn-checkout"
        type="button"
        variant="contained"
        color="primary"
        onClick={handleConfirm}
        fullWidth
      >
        Done
      </LoadingButton>
    </div>
  );
};

export default observer(SubscribeSuccessModal);
