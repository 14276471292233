const localStorageKey = "itb:local:authId";
const localStorageSecretKey = "itb:local:authSecret";
const localStorageUserKey = "itb:local:userid";
const localStorageMeasurementKey = "itb:local:measurement";
const localStorageActivityLevelKey = "itb:local:activitylevel";
const localStoragePlanKey = "itb:local:plan";

export function getAuthId() {
  return localStorage.getItem(localStorageKey);
}
export function setAuthId(authId: string) {
  return localStorage.setItem(localStorageKey, authId);
}
export function getUserId() {
  return localStorage.getItem(localStorageUserKey);
}
export function setUserId(id: string) {
  return localStorage.setItem(localStorageUserKey, id);
}
export function setAuthSecret(authSecret: string) {
  return localStorage.setItem(localStorageSecretKey, authSecret);
}
export function getAuthSecret() {
  return localStorage.getItem(localStorageSecretKey);
}

export function clearUserInfo() {
  localStorage.removeItem(localStorageKey);
  localStorage.removeItem(localStorageUserKey);
  localStorage.removeItem(localStorageSecretKey);
}

export function getMeasurement() {
  return localStorage.getItem(localStorageMeasurementKey);
}
export function setMeasurement(measurement: string) {
  return localStorage.setItem(localStorageMeasurementKey, measurement);
}
export function getActivityLevel() {
  return localStorage.getItem(localStorageActivityLevelKey);
}
export function setActivityLevel(activitylevel: string) {
  return localStorage.setItem(localStorageActivityLevelKey, activitylevel);
}
export function getSelectPlan() {
  return localStorage.getItem(localStoragePlanKey);
}
export function setSelectPlan(plan: string) {
  return localStorage.setItem(localStoragePlanKey, plan);
}

export function clearSignUpData() {
  localStorage.removeItem(localStorageMeasurementKey);
  localStorage.removeItem(localStorageActivityLevelKey);
  localStorage.removeItem(localStoragePlanKey);
}

export function setLoginInfo(data: any) {
  setAuthId(data.authId);
  setUserId(data.id);
  setAuthSecret(data.authSecret);
}
