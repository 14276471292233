import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SimpleCardPage from "components/SimpleCradPage";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { get } from "lodash-es";
import LoadingButton from "components/LoadingButton";
import { shouldDisabledButton } from "helpers/common";
import { resetPassword } from "api/Auth";
import { useHistory } from "react-router-dom";
import { useQuery } from "helpers/useHooks";
import { useSnackbar } from "notistack";
import AdornmentInput from "components/AdornmentInput";
import { InputAdornment, IconButton } from "@material-ui/core";
import EyeOpen from "icons/eye-open";
import EyeClose from "icons/eye-close";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "290px",
    height: "255px",
  },
  send: {
    margin: theme.spacing(4, 0, 1),
  },
  input: {
    margin: "0px 0px 10px 0px",
  },
}));

const resetPasswordSchema = yup.object().shape({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function ResetPassword() {
  const history = useHistory();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { register, handleSubmit, watch, errors } = useForm({
    mode: "onBlur",
    validationSchema: resetPasswordSchema,
    defaultValues: { password: "", confirmPassword: "" },
  });
  const onSubmit = (data: any) => {
    if (query.token) {
      setLoading(true);
      resetPassword({
        token: String(query.token),
        password: data.password,
      })
        .then(() => {
          enqueueSnackbar("Password reset successfull! Redirect to login...", {
            variant: "success",
          });
          setTimeout(() => {
            history.push("/login");
          }, 500);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar("Invalid token", {
        variant: "error",
      });
    }
  };
  const disabled = shouldDisabledButton(watch, errors);
  return (
    <SimpleCardPage className="page-reset-password" hideBack>
      <Box className="simple-card-page-title">Reset Password</Box>
      <Box className="simple-card-page-description">
        Please enter &amp; confirm your new password
      </Box>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <AdornmentInput
          inputRef={register}
          error={!!errors.password}
          helperText={get(errors, "password.message")}
          className={classes.input}
          margin="normal"
          fullWidth
          id="password"
          label="Password"
          name="password"
          autoComplete="password"
          type={showPassword ? "text" : "password"}
        />
        <AdornmentInput
          inputRef={register}
          error={!!errors.confirmPassword}
          helperText={get(errors, "confirmPassword.message")}
          className={classes.input}
          margin="normal"
          fullWidth
          id="confirmPassword"
          label="Confirm Password"
          name="confirmPassword"
          autoComplete="confirmPassword"
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <EyeOpen /> : <EyeClose />}
              </IconButton>
            </InputAdornment>
          }
        />
        <LoadingButton
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={disabled}
          className={classes.send}
        >
          Reset password
        </LoadingButton>
      </form>
    </SimpleCardPage>
  );
}
