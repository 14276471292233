import React, { ReactNode, ReactElement } from "react";

type Props = { children: ReactNode; contextProviders: ReactElement[] };

export const ContextProviderComposer = ({
  contextProviders,
  children,
}: Props) => {
  return contextProviders.reduceRight(
    (children, parent, index) => React.cloneElement(parent, { children }),
    children,
  ) as ReactElement;
};
