import palette from "./palette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { Palette } from "@material-ui/core/styles/createPalette";

const typography:
  | TypographyOptions
  | ((palette: Palette) => TypographyOptions) = {
  fontFamily: [
    "Avenir LT Std",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontWeightRegular: 600,
  h1: {
    fontSize: 32,
    lineHeight: 1.25,
    fontWeight: 900,
    color: palette!.text?.primary,
  },
  h2: {
    fontSize: 30,
    lineHeight: 1.3333,
    fontWeight: 800,
    color: palette!.text?.primary,
  },
  h3: { fontSize: 26, lineHeight: 1.23, color: palette!.text?.primary },
  h4: { fontSize: 26, lineHeight: 1.23, color: palette!.text?.primary },
  h5: { fontSize: 26, lineHeight: 1.23, color: palette!.text?.primary },
  h6: {
    color: palette!.text?.primary,
  },
  subtitle1: {},
  subtitle2: {},
  body1: {},
  body2: {},
  button: {},
  caption: {},
  overline: {},
};

export default typography;
