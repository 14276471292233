import React from "react";
import { makeStyles, Box, CircularProgress } from "@material-ui/core";

interface LadingContainerProps {
  isLoading?: boolean;
  visiableWhenLoading?: boolean;
  maskStyle?: any;
}

const useStyle = makeStyles(() => ({
  root: {
    overflowX: "auto",
    position: "relative",
  },
  loadingBox: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.05)",
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const LadingContainer: React.FC<LadingContainerProps> = ({
  isLoading,
  visiableWhenLoading = false,
  children,
  maskStyle,
}) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      {isLoading && (
        <Box className={classes.loadingBox} style={maskStyle}>
          <CircularProgress />
        </Box>
      )}
      <div
        style={{
          overflow: "hidden",
          visibility: !visiableWhenLoading && isLoading ? "hidden" : "visible",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default LadingContainer;
