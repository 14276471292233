import { useContext } from "react";
import DialogContext from "./DialogContext";
import { DialogConfig, AsyncOpenDialogResult } from "./DialogProvider";

interface DialogContextType {
  closeDialog(): void;
  openDialog(dialogConfig: DialogConfig): Promise<AsyncOpenDialogResult>;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { closeDialog, openDialog } = useContext(
    DialogContext,
  ) as DialogContextType;
  return {
    openDialog,
    closeDialog,
  };
};
