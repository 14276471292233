import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  circle: {
    stroke: "url(#progressColors)",
  },
}));

export default function GradientCircularProgress() {
  const classes = useStyles({});

  return (
    <>
      <svg width="130" height="130">
        <linearGradient id="progressColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="30%" stopColor="#a8d6e7" />
          <stop offset="95%" stopColor="#a8d6e7" />
        </linearGradient>
      </svg>
      <CircularProgress
        variant="determinate"
        size={130}
        thickness={3}
        value={50}
        className="plan-daily-allowance-progress"
        classes={{ circle: classes.circle }}
      />
    </>
  );
}
