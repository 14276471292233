import { WeightUnit, HeightUnit } from "./enum";

export const heightUnits = [
  { value: HeightUnit.FEET_AND_INCHES, text: "ft in" },
  { value: HeightUnit.CENTIMETERS, text: "cm" },
];
export const weigthUnits = [
  { value: WeightUnit.POUNDS, text: "lbs" },
  { value: WeightUnit.STONES, text: "st" },
  { value: WeightUnit.KILOGRAMS, text: "kg" },
];
