import palette from "../palette";

export default {
  root: {
    height: 24,
    fontSize: 14,
    fontWeight: 550,
    lineHeight: 2.4,
    textTransform: "none",
    color: palette.text.disabled,
    marginBottom: 0,
    marginLeft: 2,
    "&.Mui-error": {
      color: palette.text.disabled,
    },
  },
  shrink: {
    "&.MuiInputLabel-outlined": {
      letterSpacing: 0.75,
      fontWeight: 700,
      transform: "translate(0px, 0px) scale(0.75)!important",
      color: "#8B90A0",
      textTransform: "uppercase",
    },
  },
  outlined: {
    transform: "translate(14px, 30px) scale(1)",
  },
};
