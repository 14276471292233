import React, { useState } from "react";
import GradientCircularProgress from "components/GradientCircularProgress";
import { Grid, Box, Typography, Button, TextField } from "@material-ui/core";
import "./index.scss";
import { appStore } from "models";
import { getPlan } from "config/plans";
import LoadingButton from "components/LoadingButton";
import { NavLink } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { LossPlan } from "config/enum";
import { inchesToString } from "helpers/calculate";
import { toFixed } from "helpers/math";
import { observer } from "mobx-react";
import { invitationSms } from "api/Sms";
import { safeDateFormat } from "helpers/format";
import IconCheck from "icons/feature/check";
import NumberFormat from "react-number-format";
import useDialog from "components/Dialog/useDialog";
import LeanMoreAboutProModalModal from "views/ManageSubscription/LeanMoreAboutProModal";
import FacebookCAPI from "helpers/facebookCAPI";
import GoogleGA from "helpers/googleGA";

interface Props {}

const getUnitForPlan = (lossPlan: number) => {
  if (lossPlan === LossPlan.KEEPING_KETO) {
    return "NET CARBS";
  } else if (lossPlan === LossPlan.CALORIE_COMMAND) {
    return "calories";
  }
  return "bites";
};

const dailyAllowanceForPlan = (
  dailyAllowance: number,
  lossPlan: number,
  isUseDecimals?: boolean,
) => {
  if (lossPlan === LossPlan.CALORIE_COMMAND) {
    return String(toFixed(dailyAllowance, 0));
  } else if (lossPlan === LossPlan.KEEPING_KETO) {
    return String(toFixed(dailyAllowance, 1));
  } else {
    if (isUseDecimals) {
      return String(toFixed(dailyAllowance, 1));
    } else {
      return String(toFixed(dailyAllowance, 0));
    }
  }
};

const AccountOverview: React.FC<Props> = () => {
  const currentUser = appStore.currentUser;
  const plan = getPlan(currentUser.lossPlan);
  const isPro = currentUser.isProUser;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const { handleSubmit, control } = useForm({});
  const { openDialog } = useDialog();

  const handleTextMeTheApp = (data: any) => {
    if (!data.phoneNumber) {
      return setError("please input phone number");
    }
    if (
      data.phoneNumber &&
      !/\+1 \(\d{3}\) \d{3}-\d{4}/.test(data.phoneNumber)
    ) {
      return setError("invalid phone number");
    }
    setLoading(true);
    invitationSms(data.phoneNumber)
      .then((resp) => {
        setLoading(false);
        setSuccess(true);
      })
      .finally(() => {
        setLoading(false);
      });

      FacebookCAPI.logEvent('contact');
      GoogleGA.logEvent('contact');
  };
  const dailyAllowance = dailyAllowanceForPlan(
    currentUser.dailyAllowance,
    currentUser.lossPlan,
    currentUser.isUseDecimals,
  );

  return (
    <Grid className="page-account-view" spacing={6} container>
      <Grid xs={12} md={6} item>
        <Box className="content-box profile-box">
          <div className="box-sub-title">PROFILE</div>
          <div className="profile-info-row">
            <i className="profile-icon icon-mail"></i>
            {currentUser.email}
          </div>
          <div className="profile-info-row">
            <i className="profile-icon icon-cake"></i>
            {safeDateFormat(currentUser.birthDate, "MMMM d, yyyy")}
          </div>
          <div className="profile-info-row">
            <i className="profile-icon icon-height"></i>
            {inchesToString(currentUser.heightInch, currentUser.heightUnit)}
          </div>
          <NavLink to="/profile">
            <div className="content-box-link">Edit Profile &gt;</div>
          </NavLink>
        </Box>
      </Grid>
      <Grid xs={12} md={6} item>
        <Box className={`content-box membership-box ${isPro ? "pro" : "free"}`}>
          <div className="box-sub-title">MEMBERSHIP</div>
          <Typography variant="h2" className="membership-title">
            {isPro ? "PRO" : "Basic"}
          </Typography>
          {isPro ? (
            <div className="pro-description">
              You’re ready to supercharge your weight loss with our most popular
              PRO features.
            </div>
          ) : (
            <div className="change-to-pro">
              <Typography className="change-to-pro-description">
                <strong>Supercharge</strong> your weight loss with unlimited
                access to our most popular <strong>PRO features</strong>.
              </Typography>
              <Button
                variant="text"
                className="btn-go-pro"
                onClick={() => {
                  FacebookCAPI.logEvent('page_view_upgrade');
                  GoogleGA.logEvent('page_view_upgrade');
                  openDialog({
                    component: LeanMoreAboutProModalModal,
                    className: "learn-more-about-pro-modal",
                  });
                }}
              >
                Explore pro
              </Button>
            </div>
          )}
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box className={`content-box plan-box ${plan.className}`}>
          <div className="plan-bg plan-box-bg-position"></div>
          <div className="plan-box-left">
            <div className="box-sub-title">Your PLAN</div>
            <div className="plan-info-row">
              <Typography variant="h2" className="plan-title">
                {plan.title}
              </Typography>
              <div className="plan-icon plan-icon plan-icon-bg" />
            </div>
            <div className="plan-description">{plan.description}</div>
            <NavLink to="/plan-summary">
              <div className="content-box-link btn-change-plan">
                Change Your Plan &gt;
              </div>
            </NavLink>
          </div>
          <div className="plan-box-right">
            <div className="plan-daily-allowance">
              <div className="box-sub-title">Daily Allowance</div>
              <div className="plan-daily-allowance-chart">
                <GradientCircularProgress />
                <div className="plan-daily-allowance-number">
                  {dailyAllowance}
                </div>
              </div>
              <div className="plan-daily-allowance-description">
                {`Your daily allowance is ${dailyAllowance} ${getUnitForPlan(
                  currentUser.lossPlan,
                )}.`}
              </div>
            </div>
          </div>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box className="content-box get-mobile-app-box">
          <div className="box-sub-title">MOBILE APP</div>
          <div className="submit-your-phone-number-description">
            Enter your phone number for a direct link to download Healthi on
            your phone.
          </div>
          <div className="submit-phone-number-form">
            <Controller
              as={NumberFormat}
              customInput={TextField}
              control={control}
              format="+1 (###) ###-####"
              error={!!error}
              helperText={
                success ? (
                  <>
                    A direct link has been sent to your number
                    <IconCheck style={{ height: 16, marginTop: -3 }} />
                  </>
                ) : (
                  error
                )
              }
              onKeyDown={() => {
                setError("");
                setSuccess(false);
              }}
              className="phone-number-input"
              variant="outlined"
              margin="normal"
              fullWidth
              id="phone"
              type="phone"
              placeholder="+1 (000) 000-0000"
              name="phoneNumber"
            />
            {/* <TextField
              inputRef={register}
              error={!!errors.phoneNumber}
              helperText={
                success ? (
                  <>
                    A direct link has been sent to your number
                    <IconCheck style={{ height: 16, marginTop: -3 }} />
                  </>
                ) : (
                  get(errors, "phoneNumber.message")
                )
              }
              className="phone-number-input"
              variant="outlined"
              margin="normal"
              fullWidth
              id="phone"
              type="phone"
              placeholder="+1 (000) 000-0000"
              name="phoneNumber"
              autoComplete="phone"
            /> */}
            <LoadingButton
              loading={loading}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit(handleTextMeTheApp)}
              className="btn-submit-phone-number btn-red"
            >
              text me the app
            </LoadingButton>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default observer(AccountOverview);
