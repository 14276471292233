import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function ForwardArrow(props) {
  return (
    <SvgIcon
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...props.style, color: "white" }}
    >
      <path
        d="M1 1L5 5L1 9"
        stroke="#959FAF"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
