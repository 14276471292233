import React from "react";

interface Props {}

const ConfirmDeleteAccountDialog = (props: Props) => {
  return (
    <div>
      <div className="before-you-go">Before you go...</div>
      <ol className="before-you-go-tips">
        <li className="before-you-go-tip">
          If you no longer want our emails, tap “turn off” at the bottom of any
          email
        </li>
        <li className="before-you-go-tip">
          You must use the App Store or Google Play Store to cancel your
          subscription
        </li>
      </ol>
      <div className="last-thing">
        Please note, once deleted there is no way to restore your account or
        data.
      </div>
    </div>
  );
};

export default ConfirmDeleteAccountDialog;
