import { getUserInfo, getPromoCode } from "api/Auth";
import { appStore } from "models";
import { getSubscription, getStripePlan } from "api/Checkout";
import { get, noop } from "lodash-es";
import { updateUserInfo } from "api/Auth";
import { getPlan } from "config/plans";
import useDialog from "components/Dialog/useDialog";
import CheckoutModal from "views/CheckoutModal";
import SubscribeSuccessModal from "views/CheckoutModal/SubscribeSuccessModal";
import { getExperimentPricing } from "api/Pricing";
import FacebookCAPI from "helpers/facebookCAPI";
import GoogleGA from "helpers/googleGA";

export const appInitGetUserInfo = async (userId, needTrack) => {
  const result = await getExperimentPricing();
  const productId = get(result, "[0].group.param.productId");
  const experimentName = get(result, "[0].name");
  const user = await getUserInfo(userId);

  if (productId) {
    appStore.productId = productId;

    // track price variant in Amplitude
    user.priceVariant = {
      experimentName: experimentName,
      productId: productId,
    };
  }

  appStore.setCurrentUser(user, true);

  const plan = await getStripePlan();
  appStore.setStripePlans(get(plan, "data", []));

  const promo = await getPromoCode();
  if (!sessionStorage.getItem("clearPromo")) {
    appStore.setPromo(promo);
  }

  try {
    await getSubscription();
    appStore.stripeSubscriptionExpired = false;
  } catch (error) {
    appStore.stripeSubscriptionExpired = true;
  }
};

export const syncUserInfo = async (needTrack = false) => {
  const [user, plan, promo] = await Promise.all([
    getUserInfo(appStore.currentUser.id),
    getStripePlan(),
    getPromoCode(),
  ]);
  appStore.setCurrentUser(user, needTrack);
  appStore.setStripePlans(get(plan, "data", []));

  if (!sessionStorage.getItem("clearPromo")) {
    appStore.setPromo(promo);
  }

  try {
    await getSubscription();
    appStore.stripeSubscriptionExpired = false;
  } catch (error) {
    appStore.stripeSubscriptionExpired = true;
  }
};

export const syncUserPlan = async () => {
  const plan = await getStripePlan();
  appStore.setStripePlans(get(plan, "data", []));
};

export const useCreateSubscription = () => {
  const { openDialog } = useDialog();
  return async () => {
    await syncUserInfo();
    if (appStore.currentUser.isProUser) return;
    FacebookCAPI.logEvent('add_to_cart');
    GoogleGA.logEvent('add_to_cart');
    GoogleGA.addToCart('USD', 29.99);
    const result = await openDialog({
      component: CheckoutModal,
      className: "checkout-modal",
    }).catch(noop);
    if (result) {
      openDialog({
        component: SubscribeSuccessModal,
        className: "sub-success-modal",
      });
    }
    return result;
  };
};

export const useChangeUserPlan = () => {
  const handleCreateSubscription = useCreateSubscription();
  return async (plan: number, updateOnBoarding?: boolean) => {
    await syncUserInfo();
    if (!appStore.currentUser.isProUser && getPlan(plan).isPro) {
      const result = await handleCreateSubscription();
      if (!result) {
        return Promise.reject({});
      }
    }
    return updateUserInfo({ lossPlan: plan }, updateOnBoarding);
  };
};
