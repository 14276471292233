import http from "./Http";

export const invitationSms = (phoneNumber: string) => {
  return http
    .post("web/invitation-sms", {
      to: String(phoneNumber).trim(),
    })
    .then((resp) => {
      if (resp.status === 200) {
        return Promise.resolve(resp.data);
      } else {
        return Promise.reject(resp);
      }
    });
};
