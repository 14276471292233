import { toFixed } from "./math";
import { get } from "lodash-es";
import { isValid, parse } from "date-fns";
import { format, toDate } from "date-fns-tz";
// import { setLocale } from "yup";

export const FeetInchesFormatter = (num = "") => {
  const numberStr = String(num);
  if (numberStr.length > 0) {
    const ret = /(\d{1})[^\d]*(\d{1,2})?[^\d]*/.exec(numberStr);
    if (ret) {
      const [, ft, inch = ""] = ret;
      return `${ft}' ${Number(inch) < 11 ? inch : 11}"`;
    }
  }
  return num;
};

export const FeetInchesRemoveFormatting = (value) => {
  return value;
};

// export const FeetInchesFormatter = "#' ##\"";

export const StonesFormatter = (num: number) => {
  const numberStr = String(num);
  if (numberStr.length > 0) {
    const reg =
      Number(numberStr[0]) >= 5
        ? /(\d{1})[^\d]*(\d{1,2})?[^\d]*/
        : /(\d{1,2})[^\d]*(\d{1,2})?[^\d]*/;
    const ret = reg.exec(numberStr);
    if (ret) {
      const [, st, lb = ""] = ret;
      return `${st} st ${Number(lb) < 13 ? lb : 13} lbs`;
    }
  }
  return "";
};

export const StonesRemoveFormatting = (value) => {
  const ret = /(\d*) st (\d*) lbs/.exec(value);
  if (ret) {
    const [, st = "", lb = ""] = ret;
    return `${st}${lb}`;
  }
  return value;
};

export const getPriceString = (activeStripePlan) => {
  return `$${get(activeStripePlan, "amount", 0) / 100}`;
};

export const getOriginPriceString = (activeStripePlan) => {
  // use metadata original price (when it exists)
  const originalPrice = get(activeStripePlan, "metadata.original_price", null);
  if (originalPrice) {
    return originalPrice;
  }

  // use calculated original price (when not able to use metadata original price)
  const amount = get(activeStripePlan, "amount", 0);
  const discount = get(activeStripePlan, "metadata.discount", 0);
  const price = discount
    ? toFixed(Math.ceil((amount / discount) * 100) / 100, 2)
    : amount / 100;
  return `$${price}`;
};

export const getPriceCopyFromStripePlan = (stripePlan) => {
  // let metadata = get(stripePlan, "metadata", []);
  return get(
    stripePlan,
    "metadata.pricecopy",
    "You're ready to supercharge your weight loss with our most popular PRO features.",
  );
};

export const safeDateFormat = (date, options, inputFormat = "yyyy-MM-dd") => {
  let datestring = date;
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // for safari can not parse date
  if (date && date.length && date.length > 10) {
    datestring = date.substring(0, 10);
  }

  if (date) {
    const d = parse(datestring, inputFormat, new Date());
    const newDate = toDate(d, { timeZone: tz });

    if (isValid(newDate)) {
      return format(newDate, options, { timeZone: tz });
    } else {
      return null;
    }
  }

  return null;
};
