import * as colors from "@material-ui/core/colors";

const white = "#FFFFFF";

const palette = {
  type: "light",
  primary: {
    contrastText: white,
    main: "#357392",
  },
  secondary: {
    contrastText: white,
    main: "#4285F5",
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: "#2B2B2B",
    secondary: "#357392",
    disabled: "#959FAF",
    hint: "#357392",
  },
  background: {
    default: "#F8F8F8",
    paper: white,
  },
  grey: {},
  divider: colors.grey[200],
};

export default palette;
