export default {
  root: {},
  contained: {
    height: 50,
    borderRadius: 5,
    padding: 14,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "22px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: "#BFC6D4",
    },
  },
};
