/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from "react";
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import "./index.scss";
import { appStore } from "models";
import { useSnackbar } from "notistack";
import { useForm, OnSubmit, Controller } from "react-hook-form";
import { get, find, noop } from "lodash-es";
import LoadingButton from "components/LoadingButton";
import {
  ControledDatePicker,
  NumberFormatWithError,
} from "components/FormHelper";
import AdornmentInput from "components/AdornmentInput";
import { updateUserInfo, deleteAccount } from "api/Auth";
import { observer } from "mobx-react";
import { shouldDisabledButton } from "helpers/common";
import { heightUnits } from "config/unit";
import { HeightUnit } from "config/enum";
import { FeetInchesFormatter } from "helpers/format";
import MySelect from "components/MySelect";
import { genderOptions } from "./SelectGender";
import * as yup from "yup";
import { validateHeight } from "helpers/validate";
import useDialog from "components/Dialog/useDialog";
import { useHistory } from "react-router-dom";
import ConfirmDeleteAccountDialog from "./ConfirmDeleteAccountDialog";
import EditAvatar from "./EditAvatar";
import SimpleMenu from "components/SimpleMenu";
import { inchesToUnit, getInches } from "helpers/calculate";
import Amplitude from "amplitude-js";
import Appboy from "appboy-web-sdk";

interface Props {}

const watchRef: any = {
  current: {
    heightUnit: appStore.currentUser.heightUnit,
  },
};

const getCurrentWatchRef = () => watchRef.current;

const EditProfileSchema = yup.object().shape({
  gender: yup.number().required(),
  birthDate: yup.string().nullable().required(),
  name: yup.string().min(2).required(),
  username: yup
    .string()
    .trim()
    .transform((value) => (value === "" ? null : value))
    .min(6)
    .nullable(),
  about: yup.string(),
  email: yup.string().required().email("Invalid email"),
  height: yup
    .string()
    .required()
    .test(validateHeight(getCurrentWatchRef) as any),
  heightUnit: yup.number().required(),
  isCcpaOptOut: yup.boolean(),
});

const EditProfile: React.FC<Props> = () => {
  const currentUser = appStore.currentUser;
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    clearError,
    control,
    formState,
    setValue,
  } = useForm({
    mode: "onBlur",
    validationSchema: EditProfileSchema,
    defaultValues: {
      ...currentUser,
    },
  });

  watchRef.current = watch();
  const { heightUnit } = watchRef.current;
  const heightUnitFormat =
    heightUnit === HeightUnit.FEET_AND_INCHES ? FeetInchesFormatter : null;
  const onUnitChange = (unitType: string, currentUnit: number) => {
    if (unitType === "heightUnit") {
      const { height, heightUnit } = watchRef.current;
      height &&
        setValue(
          "height",
          inchesToUnit(getInches(height, heightUnit), currentUnit, 1) as any,
        );
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const disabled =
    !formState.dirty ||
    shouldDisabledButton(watch, errors, [
      "name",
      "email",
      "gender",
      "birthDate",
      "height",
    ]);

  /* eslint-disable @typescript-eslint/ban-types */
  const onSubmit: OnSubmit<{}> = async (data: any) => {
    let confirmUsernameChange: any = true;
    if (currentUser.isCcpaOptOut !== data.isCcpaOptOut) {
      Amplitude.logEvent("CCPA Opt-out Set", {
        ccpa_opt_out: data.isCcpaOptOut,
      });
      Appboy.logCustomEvent("CCPA Opt-out Set", {
        ccpa_opt_out: data.isCcpaOptOut,
      });
    }
    if (currentUser.username !== data.username) {
      confirmUsernameChange = await openDialog({
        subTitle: "Confirm Username",
        title: `Are you sure you want '${data.username}' as your username? You won't be able to change it once selected.`,
        cancelText: "Cancel",
        confirmText: "Save",
      });
    }
    if (confirmUsernameChange) {
      setLoading(true);
      updateUserInfo(data)
        .then(() => {
          enqueueSnackbar(`Profile updated.`, {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        })
        .finally(() => setLoading(false));
    }
  };
  const { openDialog } = useDialog();
  const history = useHistory();
  const handleDeleteAccount = () => {
    openDialog({
      subTitle: "delete account",
      title: "We’re sorry to see you go",
      className: "confirm-delete-account",
      content: <ConfirmDeleteAccountDialog />,
      cancelText: "delete account",
      confirmText: "cancel",
      isAsync: true,
    })
      .then(async ({ result, resolve }) => {
        if (result === false) {
          appStore.setAppLoading(true);
          await deleteAccount().then(() => {
            resolve();
            appStore.logout();
            history.push("/login");
          });
          appStore.setAppLoading(false);
        }
        resolve();
      })
      .catch(noop);
  };

  const heightUnitPicker = (
    <Controller
      name="heightUnit"
      control={control}
      as={
        <SimpleMenu
          options={heightUnits}
          onValueChange={(value) => onUnitChange("heightUnit", value)}
          anchor={(props) => (
            <div>
              {get(find(heightUnits, { value: heightUnit } as any), "text")}
            </div>
          )}
        ></SimpleMenu>
      }
    ></Controller>
  );
  const usernameInputDisabled = !!currentUser.username;
  const explainWhyUserNameCantChange = () => {
    openDialog({
      subTitle: "Your Username",
      title: "You are not able to change your username once selected.",
      cancelText: null,
      confirmText: "Got it!",
    });
  };
  const ccpaUrlClicked = () => {
    Amplitude.logEvent("CCPA Opt-Out Viewed");
    Appboy.logCustomEvent("CCPA Opt-Out Viewed");
    window.open("https://oag.ca.gov/privacy/ccpa");
  };
  const [checked, setChecked] = React.useState(
    currentUser.isCcpaOptOut === true,
  );
  return (
    <Grid className="page-edit-profile" spacing={6} container>
      <Grid xs={12} item>
        <Box className="content-box edit-profile-box">
          <div className="box-title">Edit Profile</div>
          <form>
            <EditAvatar></EditAvatar>
            <Grid container spacing={6} className="profile-form">
              <Grid xs={12} md={6} item>
                <TextField
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  placeholder="Name"
                  error={!!errors.name}
                  helperText={get(errors, "name.message")}
                  autoComplete="name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <TextField
                  onClick={
                    usernameInputDisabled ? explainWhyUserNameCantChange : noop
                  }
                  inputRef={register}
                  onChange={(event) => {
                    event.target.value = String(event.target.value).substr(
                      0,
                      20,
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  placeholder="Username"
                  error={!!errors.username}
                  helperText={get(errors, "username.message")}
                  autoComplete="username"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={usernameInputDisabled}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  inputRef={register}
                  onChange={(event) => {
                    event.target.value = String(event.target.value).substr(
                      0,
                      30,
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  id="about"
                  label="About"
                  name="about"
                  placeholder="Tell us more about yourself."
                  error={!!errors.about}
                  helperText={get(errors, "about.message")}
                  autoComplete="about"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <TextField
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email"
                  placeholder="Email"
                  name="email"
                  error={!!errors.email}
                  helperText={get(errors, "email.message")}
                  autoComplete="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <Controller
                  as={MySelect}
                  control={control}
                  options={genderOptions}
                  id="gender"
                  label="gender"
                  name="gender"
                  fullWidth
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <Controller
                  as={ControledDatePicker}
                  control={control}
                  name="birthDate"
                  label="birthDay"
                  placeholder="BirthDate"
                  errors={errors}
                  setError={setError}
                  clearError={clearError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <Controller
                  as={NumberFormatWithError}
                  name="height"
                  control={control}
                  customInput={AdornmentInput}
                  format={heightUnitFormat}
                  id="height"
                  label="Height"
                  placeholder="Height"
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{
                        borderRadius: 22,
                        border: "1px solid #BFC6D5",
                        height: 24,
                        textAlign: "center",
                        fontSize: 14,
                        lineHeight: 1.71,
                        padding: "0px 10px",
                        color: "#32355580",
                        cursor: "pointer",
                      }}
                    >
                      {heightUnitPicker}
                    </InputAdornment>
                  }
                  errors={errors}
                  fullWidth
                  shrink
                />
              </Grid>
              <Grid xs={12} md={12} direction="row" container item>
                <h3>California Consumer Privacy Act Opt-Out</h3>
                <p>
                  You can now opt out of any third-party services that this
                  product utilizes for business purposes. This can possibly
                  reduce the features provided, but all core functionality will
                  not be affected. Learn more here:
                  <Button className="btn-link" onClick={ccpaUrlClicked}>
                    https://oag.ca.gov/privacy/ccpa
                  </Button>
                </p>

                <Grid 
                  xs={6}
                  md={6}
                  justifyContent="flex-end"
                  direction="row-reverse"
                  alignItems="center"
                  container item
                >
                  <FormControlLabel
                    labelPlacement="start"
                    className="MuiInputBase-root"
                    control={
                      <Checkbox
                        inputRef={register}
                        name="isCcpaOptOut"
                        id="isCcpaOptOut"
                        color="primary"
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                      />
                    }
                    label="CCPA Opt-Out"
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid xs={12} item className="actions-row">
            <Button
              className="btn-delete-account"
              onClick={handleDeleteAccount}
            >
              Delete account
            </Button>
            <LoadingButton
              className="btn-submit-profile-form"
              loading={loading}
              type="button"
              variant="contained"
              color="primary"
              disabled={disabled}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </LoadingButton>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default observer(EditProfile);
