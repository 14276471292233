import http from "./Http";

export const getExperimentPricing = () => {
  return http.get("experiment", { params: { id: 2 } }).then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  });
};
