import React from "react";
import { IconButton } from "@material-ui/core";
import IconClose from "@material-ui/icons/Close";
import { CustomModalComponentProps } from "components/Dialog/DialogProvider";
import LeanMoreAboutPro from "./LeanMoreAboutPro";
import { useCreateSubscription } from "models/actions";

interface Props extends CustomModalComponentProps {}

const LeamMoreAboutProModal = ({ handleCancel }: Props) => {
  const handleCreateSubscription = useCreateSubscription();
  return (
    <div className="">
      <IconButton className="btn-close-modal" onClick={handleCancel}>
        <IconClose></IconClose>
      </IconButton>
      <LeanMoreAboutPro
        handleCreateSubscription={handleCreateSubscription}
        isModal={true}
      />
    </div>
  );
};

export default LeamMoreAboutProModal;
