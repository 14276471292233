/* eslint-disable no-throw-literal */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from "react";
import { ErrorResult } from "./util";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { Link } from "@material-ui/core";
import { get } from "lodash-es";
import { TERMS_PRIVACY } from "config";
import LoadingButton from "components/LoadingButton";
import { getPriceString } from "helpers/format";
import { appStore } from "models";
import { observer } from "mobx-react";

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontWeight: "400",
      fontSize: "16px",
      fontFamily: "Arial, Helvetica, sans-serif",
      lineHeight: "1.5",
      color: "#323555",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#A9ABB9",
      },
    },
    invalid: {
      color: "#357392",
    },
  },
};

const PaymentMethodForm = ({ handleSubmitPaymentMethod, buttonText }) => {
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const error = appStore.checkoutFormError;
  const clearError = () => appStore.setCheckoutFormError({});

  const currentUser = appStore.currentUser;
  const subscriptionRenewalAmount = get(
    currentUser,
    "subscription.stripe.subscriptions.data[0].items.data[0].plan.amount_decimal",
  );
  const subscriptionRenewalAmountDisplay = subscriptionRenewalAmount ? "$" + subscriptionRenewalAmount / 100 : "";

  useEffect(() => {
    return clearError;
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    clearError(); // clear and wait error
    setLoading(true);

    try {
      const cardElement = elements.getElement(CardElement);

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (payload.error) {
        throw payload.error;
      }
      appStore.setCheckoutFormError({});
      await handleSubmitPaymentMethod(payload.paymentMethod);
    } catch (error) {
      appStore.setCheckoutFormError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="modal-info-form">
      <div className="modal-info-block payment-method-card-form">
        <CardElement options={ELEMENT_OPTIONS} />
      </div>

      {get(error, "message") && (
        <div className="error-message">
          <ErrorResult>{get(error, "message")}</ErrorResult>
        </div>
      )}
      <div className="modal-info-block final-description">
        Your account will be charged {subscriptionRenewalAmountDisplay}
        /{get(appStore.activeStripePlan, "interval", "")} plus tax, if any on a
        recurring basis. Your yearly subscription will continue until you
        cancel. See&nbsp;
        <Link href={TERMS_PRIVACY} target="_blank">
          Terms of Use
        </Link>
        &nbsp;for more details.
      </div>
      <LoadingButton
        className="btn-red btn-checkout"
        loading={loading}
        type="button"
        variant="contained"
        color="primary"
        disabled={!stripe}
        onClick={handleSubmit}
        fullWidth
      >
        {buttonText}
      </LoadingButton>
    </form>
  );
};

export default observer(PaymentMethodForm);
