import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function BackArrow(props) {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color: "white" }}
      {...props}
    >
      <path
        d="M17 2L7 12L17 22"
        stroke="#959FAF"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
