import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function ProBadgeLg(props) {
  return (
    <SvgIcon
      width="87"
      height="46"
      viewBox="0 0 87 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="0">
        <stop offset="0%" stopColor="#c5738f" />
        <stop offset="80%" stopColor="#9267a9" />
      </linearGradient>
      <rect width="87" height="46" rx="4" fill="url(#linearColors)" />
      <path
        d="M12.528 34H16.56V24.624H19.44C24.24 24.624 28.496 23.6 28.496 17.904C28.496 12.88 24.528 11.344 20.144 11.344H12.528V34ZM16.56 21.168V14.8H19.44C21.616 14.8 24.272 15.184 24.272 17.968C24.272 21.04 21.296 21.168 18.992 21.168H16.56ZM32.0905 34H36.1225V24.4H39.0665L44.3145 34H49.1785L43.1625 23.984C46.5225 23.504 48.4105 21.104 48.4105 17.84C48.4105 12.752 44.3145 11.344 39.9625 11.344H32.0905V34ZM36.1225 20.944V14.8H39.6105C41.6905 14.8 44.1865 15.12 44.1865 17.808C44.1865 20.752 41.5305 20.944 39.2585 20.944H36.1225ZM51.0308 22.768C51.0308 29.84 56.0228 34.576 62.9988 34.576C70.0708 34.448 75.0628 29.712 75.0628 22.64C75.0628 15.376 70.0708 10.64 62.9988 10.768C56.0228 10.768 51.0308 15.504 51.0308 22.768ZM55.2548 22.512C55.2548 18 58.3588 14.416 63.0308 14.416C67.7348 14.416 70.8388 18 70.8388 22.512C70.8388 27.344 67.7348 30.928 63.0308 30.928C58.3588 30.928 55.2548 27.344 55.2548 22.512Z"
        fill="white"
      />
    </SvgIcon>
  );
}
