import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const Wrapper: React.FC<Props> = ({ children }) => {
  return (
    <main
      className="main-wrapper"
      style={{
        height: "100vh",
        overflow: "auto",
        position: "relative",
        display: "flex",
        flexFlow: "column nowrap",
      }}
    >
      {children}
    </main>
  );
};

export default Wrapper;
