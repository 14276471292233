import React from "react";
import { Router, Redirect, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import CommissionJunction from "helpers/commissionJunction";
import GoogleGA from "helpers/googleGA";
import GuardedRoute from "components/GuardedRoute";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { DialogProvider } from "components/Dialog";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import theme from "theme";

import SingleLayout from "layouts/SingleLayout";
import MainLayout from "layouts/MainLayout";

import { AnimatedSwitch } from "react-router-transition";
import { ContextProviderComposer } from "components/ContextProviderComposer";
import SnackBarProvider from "components/SnackbarProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFns from "@date-io/date-fns";
import { AWS_CONFIG } from "config";
import { useMedia } from "react-use";
import bugsnag from "@bugsnag/js";
import { BUGSNAG_KEY } from "config/appkey";
import routers from "./router";
import Deeplink from "components/Deeplink";

const browserHistory = createBrowserHistory();

Auth.configure(AWS_CONFIG.Auth);
Storage.configure(AWS_CONFIG.Storage);

if (process.env.REACT_APP_ENV === "production") {
  bugsnag(BUGSNAG_KEY);
}

const App = () => {
  CommissionJunction.logPage();
  GoogleGA.logPage();
  GoogleGA.pageView();
  const isMobile = useMedia("(max-width: 640px)");

  return (
    <ContextProviderComposer
      contextProviders={[
        <ThemeProvider theme={theme} key="theme" children="" />,
        <SnackBarProvider key="snackbar" />,
        <MuiPickersUtilsProvider key="df" utils={DateFns} children={<></>} />,
        <DialogProvider key="dialog" children=""></DialogProvider>,
      ]}
    >
      <CssBaseline />
      <Router history={browserHistory}>
        <Switch>
          <Route exact path="/" component={Deeplink} />
          <Route path={routers.mainLayout.map(({ path }) => path)}>
            <MainLayout>
              <Switch>
                {routers.mainLayout.map(({ path, component, guarded }) => (
                  <GuardedRoute
                    key={path}
                    path={path}
                    component={component}
                    unguarded={!guarded}
                  />
                ))}
              </Switch>
            </MainLayout>
          </Route>
          <Route path={routers.singleLayout.map(({ path }) => path)}>
            <SingleLayout>
              <AnimatedSwitch
                atEnter={{ offset: !isMobile ? -1 : 0 }}
                atLeave={{ offset: !isMobile ? 1 : 0 }}
                atActive={{ offset: 0 }}
                mapStyles={(styles) => ({
                  transform: `translateX(${-styles.offset * 400}px)`,
                  opacity: 1 - Math.abs(styles.offset),
                })}
                className="switch-wrapper"
              >
                {routers.singleLayout.map(({ path, component, guarded }) => (
                  <GuardedRoute
                    key={path}
                    path={path}
                    component={component}
                    unguarded={!guarded}
                  />
                ))}
              </AnimatedSwitch>
            </SingleLayout>
          </Route>
          <GuardedRoute
            path="/not-found"
            component={routers.notFound}
            unguarded
          />
          <Redirect to="/not-found" />
        </Switch>
      </Router>
    </ContextProviderComposer>
  );
};

browserHistory.listen((...args) => {
  CommissionJunction.logPage();

  const mainWrapper = document.getElementsByClassName("main-wrapper")[0];
  if (mainWrapper) {
    try {
      mainWrapper.scrollTo(0, 0);
    } catch (e) {
      mainWrapper.scrollTop = 0;
    }

    window.scrollTo(0, 0);
  }
});

export default App;
