import FacebookCAPI from "helpers/facebookCAPI";
import GoogleGA from "helpers/googleGA";
import http from "./Http";

export const cancelSubscription = () => {
  return http.delete("web/stripe-subscription").then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const createSubscription = (plan) => {
  FacebookCAPI.logEvent('add_payment_info');
  GoogleGA.logEvent('add_payment_info');
  return http.post("web/stripe-subscription", { plan }).then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const getSubscription = () => {
  return http.get("web/stripe-subscription").then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const getStripeCoupon = (id) => {
  return http.get("web/stripe-coupon", { params: { id } }).then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const getStripeCustomer = () => {
  return http.get("web/stripe-customer").then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const createStripeCustomer = (paymentMethod) => {
  return (
    http
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .post("web/stripe-customer", { payment_method: paymentMethod })
      .then((resp) => {
        if (resp.status === 200) {
          return Promise.resolve(resp.data);
        } else {
          return Promise.reject(resp);
        }
      })
  );
};

export const deleteStripeCustomer = () => {
  return http.delete("web/stripe-customer").then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const getStripePlan = () => {
  return http.get("web/stripe-plan").then((resp) => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data);
    } else {
      return Promise.reject(resp);
    }
  });
};
