export const AMPLITUDE_API_KEY = "d9aa700db8a89ee3652bf89458aae261";
export const APPBOY_WEB_API_KEY = "372e93cc-5d43-4ec9-a698-28566a4ac50b";
export const APPSFLYER_DEV_KEY = "36nRaLppkFtRDvcC2KCQdF";
export const APPSFLYER_APPLE_APP_ID = "404299862";
export const FITBIT_CLIENT_ID = "22CM9V";
export const FITBIT_SECRET = "b65d04ba1ec14975a1c2c0a915e72db2";

export const APPTENTIVE_APP_KEY = "IOS-ITRACKBITES-SMART-WEIGHT-LOS";
export const APPTENTIVE_APP_SIGNATURE = "f894500b15b29221637da792c45b403b";

export const BUGSNAG_KEY = "193624d41619e8a3e97e333087f6ce87";
export const COMMISSION_JUNCTION_ID = "1562843";
