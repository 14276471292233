import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar, { AppBarProps } from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Hidden, Avatar, Link, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { appStore } from "models";
import useDialog from "components/Dialog/useDialog";
import { noop } from "lodash-es";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 72,
    flexGrow: 1,
    alignItems: "center",
  },
  toolbar: {
    height: 72,
    width: 1190,
  },
  navLink: {
    color: "#717289",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.5,
    marginLeft: 32,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#323D47",
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: 126,
    marginLeft: 14,
    marginRight: 10,
    display: "block",
    pointerEvents: "auto",
  },
  avatarArea: {
    flex: "auto",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  avatar: {
    width: 30,
    height: 30,
  },
  dropdown: {
    color: "black",
    height: 20,
  },
  signoutButton: {
    textTransform: "none",
    marginLeft: 12,
    marginRight: 20,
    background: "white",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.71,
    textAlign: "center",
    color: "#323555",
    opacity: 0.5,
  },
}));

type Props = {
  type: "single" | "main";
  setDrawerOpen?: any;
};

type Layout = {
  [x in Props["type"]]: {
    color: AppBarProps["color"];
    showNavLink?: boolean;
    showAvatar?: boolean;
  };
};

export const topNavLinks = [
  { text: "How It Works", url: "https://www.healthiapp.com/how-it-works/" },
  { text: "Find Your Plan", url: "https://www.healthiapp.com/find-your-plan" },
  { text: "Blog", url: "https://www.healthiapp.com/blog" },
  { text: "Help", url: "https://help.healthiapp.com/support/home" },
  { text: "Coaches", url: "https://www.healthiapp.com/coaches" },
  { text: "Podcast", url: "https://www.healthiapp.com/podcast" },
  { text: "Success Stories", url: "https://www.healthiapp.com/success-stories" },
];

const layout: Layout = {
  single: { color: "secondary" },
  main: { color: "primary", showNavLink: true, showAvatar: true },
};

const TopBar: React.FC<Props> = ({ type, setDrawerOpen }) => {
  const { color, showNavLink, showAvatar } = layout[type];
  const history = useHistory();
  const currentUser = appStore.currentUser;
  const classes = useStyles();
  const { openDialog } = useDialog();
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const handleLogout = () => {
    openDialog({
      subTitle: "Sign Out",
      title: "Are you sure you want to sign out?",
    })
      .then((result) => {
        if (result) {
          appStore.logout();
          history.push("/login");
        }
      })
      .catch(noop);
  };
  return (
    <AppBar
      position="fixed"
      color={color}
      className={clsx(classes.root, "topbar-container")}
      style={!!showNavLink ? {} : { pointerEvents: "none" }}
    >
      <Toolbar className={clsx(classes.toolbar, "header-topbar")}>
      <IconButton
          edge="start"
          className={clsx(classes.menuButton, "header-topbar-nav-button")}
          color="inherit"
          aria-label="menu"
          onClick={() => setDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>

        <Link
          className="topbar-logo"
          href="https://healthiapp.com/"
          target="blank"
        >
          <img alt="Logo" src="/images/logo.svg" className={classes.logo} />
        </Link>
        {!!showNavLink && (
          <Hidden smDown>
            {topNavLinks.map(({ text, url }) => (
              <Link
                key={url}
                href={url}
                className={classes.navLink}
                target="_blank"
              >
                {text}
              </Link>
            ))}
          </Hidden>
        )}
        
        {showAvatar && (
          <div className={clsx(classes.avatarArea, "topbar-right")}>
            <div className={classes.avatarArea} style={{ flex: "none" }}>
              <Avatar
                alt={currentUser.name}
                className={classes.avatar}
                src={currentUser.profilePhotoUrl}
              >
                <img
                  src="/images/default-avatar.svg"
                  className="user-avatar-fallback"
                  alt=""
                />
              </Avatar>
              <Button className={classes.signoutButton} onClick={handleLogout}>
                Sign Out
              </Button>
            </div>

            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  history.push("/profile");
                  handleClose();
                }}>
                Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu> */}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default observer(TopBar);
