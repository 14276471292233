export default {
  root: {
    "&:hover,&.MuiListItem-button:hover,&.Mui-selected,&.Mui-selected:hover": {
      backgroundColor: "#f4fbfe",
      borderRadius: 4,
    },
    "&.nav-link": {
      fontWeight: 600,
    },
    "&.Mui-selected": { color: "#357392", fontWeight: 800 },
  },
};
