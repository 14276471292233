import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SimpleCardPage from "components/SimpleCradPage";
import { useHistory } from "react-router-dom";
import LoadingButton from "components/LoadingButton";
import SelectPlan from "./SelectPlan";
import { Box } from "@material-ui/core";
import "./index.scss";
import PlanCard from "components/PlanCard";
import { appStore } from "models";
import { lossPlans } from "config/plans";
import MinimalPlanCard from "components/PlanCard/Minmal";
import clsx from "clsx";
import { getRecommendedPlan } from "helpers/calculate";
import LadingContainer from "components/LoadingContainer";
import { useSnackbar } from "notistack";
import { useChangeUserPlan } from "models/actions";
import { useKeyPressEvent } from "react-use";
import ForwardArrow from "icons/addtion";
import Amplitude from "amplitude-js";
import { get } from "lodash-es";
import CommissionJunction from "helpers/commissionJunction";
import FacebookCAPI from "helpers/facebookCAPI";
import GoogleGA from "helpers/googleGA";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      position: "absolute",
      top: 31,
      width: 215,
      padding: 0,
      "& .MuiStepLabel-labelContainer": {
        display: "none",
      },
      "& .MuiStepLabel-iconContainer": {
        padding: 0,
      },
      "& .MuiStep-horizontal": {
        padding: 0,
      },
    },
    form: {
      width: 290,
    },
    button: {
      width: 263,
      marginTop: 18,
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const steps = [
  {
    key: "choose-plan",
    title: "Choose your program.",
    description:
      "PRO Tip: You’re twice as likely to reach your goals with PRO compared to basic.",
  },
  {
    key: "plan-overview",
    title: "Your personalized plan is ready!",
    description: "",
    buttonText: "CONTINUE",
  },
  {
    key: "addtional-plans",
    title: "Additional Plans",
    description: "",
  },
];

export default function Setup() {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const initialPlan =
    appStore.currentUser.lossPlan ||
    getRecommendedPlan(appStore.currentUser.habits);
  const [selectedPlan, setSelectedPlan] = React.useState(initialPlan);
  const changeUserPlan = useChangeUserPlan();
  const { enqueueSnackbar } = useSnackbar();
  const currentStep = steps[activeStep];

  const updateAndNavgate = (url: string) => {
    setLoading(true);
    changeUserPlan(selectedPlan, true)
      .then((data) => {
        setLoading(false);
        history.push(url);
      })
      .catch((error) => {
        if (error && error.message) {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleNext = () => {
    if (
      ["plan-overview", "addtional-plans"].indexOf(steps[activeStep].key) > -1
    ) {
      CommissionJunction.logPage("425172");
      updateAndNavgate("/home");
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const handleAddtionalPlan = () => {
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      history.goBack();
    }
  };
  useKeyPressEvent("Enter", () => {
    if (!loading) {
      handleNext();
    }
  });

  const amount = get(appStore.activeStripePlan, "amount", 0) / 100;
  const productId = get(appStore.activeStripePlan, "metadata.productid", "");
  const code = appStore.currentPromoCode();

  Amplitude.logEvent("Onboarding: Subscribe", {
    Price: amount,
    "Product ID": productId,
    "Promo Code": code,
    Source: "web",
  });
  FacebookCAPI.logEvent('page_view_plan');
  GoogleGA.logEvent('page_view_plan');

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("productId");
    };
  }, []);

  return (
    <SimpleCardPage
      className={clsx("page-choose-plan", currentStep.key)}
      onBackClick={handleBack}
      hideBack={currentStep.key === "choose-plan"}
    >
      <Box className="simple-card-page-title">{currentStep.title}</Box>
      <Box
        className="simple-card-page-description"
        style={{ width: 412, marginBottom: 0 }}
      >
        {currentStep.description}
      </Box>
      {currentStep.key === "choose-plan" && (
        <Box style={{ paddingBottom: 32 }}>
          <SelectPlan handleNext={handleNext}></SelectPlan>
        </Box>
      )}
      {currentStep.key === "plan-overview" && (
        <Box>
          <PlanCard lossPlan={selectedPlan}></PlanCard>
        </Box>
      )}
      {currentStep.key === "addtional-plans" && (
        <div className="addtional-plans-wrapper">
          <LadingContainer
            isLoading={loading}
            visiableWhenLoading
            maskStyle={{
              borderRadius: 10,
              backgroundColor: "rgba(255,255,255,0.05)",
            }}
          >
            <div className="addtional-plans-grid">
              {lossPlans.map((plan) => (
                <span
                  className={clsx("change-plan", {
                    selected: selectedPlan === plan.lossPlan,
                  })}
                  onClick={() => {
                    setSelectedPlan(plan.lossPlan);
                    setActiveStep(activeStep - 1);
                  }}
                  key={plan.lossPlan}
                >
                  <MinimalPlanCard lossPlan={plan.lossPlan}></MinimalPlanCard>
                </span>
              ))}
            </div>
          </LadingContainer>
        </div>
      )}
      {currentStep.buttonText && (
        <LoadingButton
          loading={loading}
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleNext}
          className={classes.button}
        >
          {currentStep.buttonText}
        </LoadingButton>
      )}
      {currentStep.key === "plan-overview" && (
        <button
          type="button"
          className="see-addtional-plan"
          onClick={handleAddtionalPlan}
        >
          See Additional Plans
          <ForwardArrow
            style={{ width: "auto", height: "auto", marginLeft: 12 }}
          />
        </button>
      )}
    </SimpleCardPage>
  );
}
