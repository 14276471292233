import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SimpleCardPage from "components/SimpleCradPage";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PersonalDetail from "./PersonalDetail";
import Habits from "./Habits";
import { useForm, Controller, FormContext } from "react-hook-form";
import * as yup from "yup";
import { createAccount } from "api/Auth";
import LoadingButton from "components/LoadingButton";
import { assign } from "lodash-es";
import { shouldDisabledButton } from "helpers/common";
import {
  getCreateUser,
  getInches,
  inchesToUnit,
  getLbs,
  lbsToUnit,
} from "helpers/calculate";
import { setLoginInfo } from "helpers/authstorage";
import { useSnackbar } from "notistack";
import { appStore } from "models";
import { observer } from "mobx-react";
import { validateHeight, validateWeight } from "helpers/validate";
import { appInitGetUserInfo } from "models/actions";
import { useKeyPressEvent } from "react-use";
import FacebookCAPI from "helpers/facebookCAPI";
import "./index.scss";
import GoogleGA from "helpers/googleGA";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      position: "absolute",
      top: 31,
      width: 215,
      padding: 0,
      "& .MuiStepLabel-labelContainer": {
        display: "none",
      },
      "& .MuiStepLabel-iconContainer": {
        padding: 0,
      },
      "& .MuiStep-horizontal": {
        padding: 0,
      },
    },
    form: {
      width: 290,
    },
    button: {
      width: 290,
      marginTop: 40,
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const steps = [
  {
    key: "detail",
    title: "Personal Details",
    description: "Can you tell us more about you?",
    fields: [
      "gender",
      "birthDate",
      "height",
      "startWeight",
      "goalWeight",
      "activityLevel",
    ],
  },
  {
    key: "habits",
    title: "What’s standing in your way?",
    description:
      "This will help us create a program that aligns with your individual needs.",
    fields: ["habits"],
  },
];

const watchRef: any = {
  current: {},
};

const getCurrentWatchRef = () => watchRef.current;

const SetupSchema = yup.object().shape({
  gender: yup.number().required(),
  heightUnit: yup.number().required(),
  weightUnit: yup.number().required(),
  birthDate: yup.string().nullable().required(),
  height: yup
    .string()
    .required()
    .test(validateHeight(getCurrentWatchRef) as any),
  startWeight: yup
    .string()
    .required()
    .test(validateWeight(getCurrentWatchRef) as any),
  goalWeight: yup
    .string()
    .required()
    .test(validateWeight(getCurrentWatchRef) as any),
  activityLevel: yup.string().required(),
});

const Setup = () => {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    mode: "onBlur",
    validationSchema: SetupSchema,
    defaultValues: {
      about: undefined,
      gender: undefined,
      heightUnit: 0,
      weightUnit: 0,
      birthDate: "",
      age: undefined,
      heightInch: undefined,
      height: "",
      startWeightLbs: undefined,
      startWeight: "",
      goalWeightLbs: undefined,
      goalWeight: "",
      activityLevel: "",
      habits: [],
    },
  });
  const { control, getValues, watch, errors, setValue } = methods;
  watchRef.current = watch();
  useEffect(() => {
    if (!appStore.signUpInfo.email) {
      history.push("/signup");
    }
  }, [history]);
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      FacebookCAPI.logEvent('submit_personal_details');
      GoogleGA.logEvent('submit_personal_details');
      setActiveStep(activeStep + 1);
    } else {
      setLoading(true);
      const data = getValues();
      const createAccountParam = assign(
        {
          user: getCreateUser(data),
        },
        appStore.signUpInfo,
      );
      createAccount(createAccountParam)
        .then((result) => {
          if (result.authId) {
            appStore.clearCurrentUser();
            setLoginInfo(result);
            appInitGetUserInfo(result.id, true).then(() => {
              FacebookCAPI.logEvent('submit_needs');
              GoogleGA.logEvent('submit_needs');
              history.push("/choose-plan");
              appStore.clearSignUpInfo();
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    }
  };
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      history.goBack();
    }
  };
  const onUnitChange = (unitType: string, currentUnit: number) => {
    if (unitType === "heightUnit") {
      const { height, heightUnit } = watchRef.current;
      height &&
        setValue(
          "height",
          inchesToUnit(getInches(height, heightUnit), currentUnit, 1) as any,
          true,
        );
    } else if (unitType === "weightUnit") {
      const { startWeight, goalWeight, weightUnit } = watchRef.current;
      if (startWeight) {
        setValue(
          "startWeight",
          lbsToUnit(getLbs(startWeight, weightUnit), currentUnit, 1) as any,
          true,
        );
      }
      if (goalWeight) {
        setValue(
          "goalWeight",
          lbsToUnit(getLbs(goalWeight, weightUnit), currentUnit, 1) as any,
          true,
        );
      }
    }
  };
  const disabled =
    shouldDisabledButton(watch, errors, steps[activeStep].fields) &&
    steps[activeStep].key !== "habits";
  useKeyPressEvent("Enter", () => {
    if (!disabled) {
      handleNext();
    }
  });

  return (
    <SimpleCardPage
      className="page-setup"
      onBackClick={handleBack}
      style={{ paddingBottom: 31 }}
    >
      {/* <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        connector={<QontoConnector />}>
        {steps.map(step => (
          <Step key={step.key}>
            <StepLabel StepIconComponent={QontoStepIcon}>{null}</StepLabel>
          </Step>
        ))}
      </Stepper> */}
      <Box className="simple-card-page-title" style={{ width: 354 }}>
        {steps[activeStep].title}
      </Box>
      <Box
        className="simple-card-page-description"
        style={{ width: 412, marginBottom: 0 }}
      >
        {steps[activeStep].description}
      </Box>
      {/* <Box display={steps[activeStep].key === "gender" ? "block" : "none"}>
        <Controller
          as={SelectGender}
          name="gender"
          control={control}
          onChange={([gender]) => gender}
        />
      </Box> */}
      <Box display={steps[activeStep].key === "detail" ? "block" : "none"}>
        <form className={classes.form} noValidate>
          <FormContext {...methods}>
            <PersonalDetail onUnitChange={onUnitChange} />
          </FormContext>
        </form>
      </Box>
      <Box display={steps[activeStep].key === "habits" ? "block" : "none"}>
        <Controller as={Habits} name="habits" control={control} />
      </Box>
      <LoadingButton
        loading={loading}
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleNext}
        disabled={disabled}
        className={classes.button}
      >
        Continue
      </LoadingButton>
    </SimpleCardPage>
  );
};

export default observer(Setup);
