import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SimpleCardPage from "components/SimpleCradPage";
import { NavLink, useHistory } from "react-router-dom";
import LoadingButton from "components/LoadingButton";
import { useForm } from "react-hook-form";
import * as Auth from "api/Auth";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { get, noop } from "lodash-es";
import { InputAdornment, IconButton } from "@material-ui/core";
import { setLoginInfo } from "helpers/authstorage";
import AdornmentInput from "components/AdornmentInput";
import { appStore } from "models";
import { googleLogin } from "api/GoogleAuth";
import EyeOpen from "icons/eye-open";
import EyeClose from "icons/eye-close";
import { shouldDisabledButton } from "helpers/common";
import { appInitGetUserInfo } from "models/actions";
import { useKeyPressEvent } from "react-use";
import useDialog from "components/Dialog/useDialog";
import FacebookCAPI from "helpers/facebookCAPI";
import GoogleGA from "helpers/googleGA";

const LoginSchema = yup.object().shape({
  email: yup.string().required().email("Invalid email"),
  password: yup.string().required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "290px",
  },
  login: {
    margin: theme.spacing(2, 0, 1),
  },
  continueWithGoogle: {
    margin: theme.spacing(2, 0, 1),
    paddingLeft: 44,
    textTransform: "none",
    marginBottom: 16,
  },
  input: {
    margin: "0px 0px 10px 0px",
  },
  googleIcon: {
    backgroundColor: "white",
    height: 44,
    width: 44,
    borderRadius: 2,
    top: "3px",
    left: "3px",
    position: "absolute",
    padding: 7,
  },
  forgetPassword: {
    color: theme.palette.text.hint,
    fontSize: 12,
    fontWeight: 800,
  },
  or: {
    color: theme.palette.text.disabled,
    paddingTop: 8,
  },
  doNotHaveAccount: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    clearError,
    unregister,
    getValues,
  } = useForm({
    mode: "onBlur",
    validationSchema: LoginSchema,
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog } = useDialog();

  const login = (data) => {
    const noRedirect = !sessionStorage.getItem("r");
    Auth.login(data)
      .then(({ data }) => {
        if (data && data.authId) {
          setLoginInfo(data);
          appStore.setCurrentUser(data, true);
          appInitGetUserInfo(get(data, "id"), false);
          FacebookCAPI.logEvent('login_success');
          GoogleGA.logEvent('login_success');
        }
        noRedirect && history.push("/home");
      })
      .catch((error) => {
        if (error.status === 404) {
          openDialog({
            subTitle: "Account Not Found",
            title: "The account provided not found. Do you want sign up?",
            confirmText: "Sign Up",
          })
            .then((result) => {
              if (result) {
                setLoading(true);
                appStore.signUpInfo = {
                  email: data.email,
                  googleToken: data.googleToken,
                };
                history.push("/setup");
              }
            })
            .catch(noop);
        } else {
          console.error("error", error);
          setLoading(false);
          setError("password", "invalid password", error.message);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      });
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    login(data);
  };
  const disabled = shouldDisabledButton(watch, errors);
  useKeyPressEvent("Enter", () => {
    if (!disabled) {
      onSubmit(getValues());
    }
  });

  const continueWithGoogle = async () => {
    const profile = await googleLogin();
    if (profile) {
      const { idToken, email } = profile;
      login({ googleToken: idToken, email });
    }
  };

  useEffect(() => {
    FacebookCAPI.logEvent('page_view_login');
    GoogleGA.logEvent('page_view_login');
  }, []);

  return (
    <SimpleCardPage className="page-login">
      <Box className="simple-card-page-title">Login</Box>
      <Box className="simple-card-page-description">
        Welcome back! Log in to continue.
      </Box>
      <form className={classes.form} noValidate>
        <TextField
          inputRef={register}
          error={!!errors.email}
          helperText={get(errors, "email.message")}
          className={classes.input}
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          onKeyDown={() => clearError("password")}
        />
        <AdornmentInput
          label="Password"
          className={classes.input}
          id="filled-adornment-password"
          type={showPassword ? "text" : "password"}
          inputRef={register}
          error={!!get(errors, "password")}
          helperText={get(errors, "password.message", "") as string}
          name="password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <EyeOpen /> : <EyeClose />}
              </IconButton>
            </InputAdornment>
          }
          fullWidth
        />
        {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
        <LoadingButton
          loading={loading}
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleSubmit(onSubmit)}
          className={classes.login}
        >
          Login
        </LoadingButton>
        <Grid container direction="column" alignItems="center">
          <Grid item xs>
            <NavLink to="/forget-password" className={classes.forgetPassword}>
              Forgot Password?
            </NavLink>
          </Grid>
          <Grid item xs className={classes.or}>
            or
          </Grid>
        </Grid>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          onClick={continueWithGoogle}
          className={classes.continueWithGoogle}
          startIcon={
            <div className={classes.googleIcon}>
              <img src="/images/google.svg" alt="google" width="30"></img>
            </div>
          }
        >
          Continue with Google
        </Button>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <NavLink
              to="/signup"
              className={classes.doNotHaveAccount}
              onMouseDown={() => {
                unregister("email");
              }}
            >
              {"Don't have an account? "}
              <strong>Sign Up</strong>
            </NavLink>
          </Grid>
        </Grid>
      </form>
    </SimpleCardPage>
  );
}
