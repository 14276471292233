export const API_VERSION = "V1.0.1";
export const TERMS_PRIVACY = "https://itrackbites.com/terms/";
export const PRIVACY = "https://itrackbites.com/privacy/";
export const HELP_CENTER_URL = "https://help.itrackbites.com";

export const PRODUCT_ID_DEFAULT = "com.ellis.itrackbites.yearly.45";
export const PRODUCT_ID_YEARLY_36 = "com.ellis.itrackbites.yearly.36_v1";
export const PRODUCT_ID_YEARLY_60 = "com.ellis.itrackbites.yearly.60_v1";
export const PRODUCT_ID_YEARLY_12 =
  "com.ellis.itrackbites.yearly.grandfathered.12";
export const PRODUCT_ID_BACKUP_SYNC = "com.ellis.itrackbites.backupsync";
export const PRODUCT_ID_ALL_GUIDES = "com.ellis.itrackbites.allfoodguides";
export const PRODUCT_ID_ALL_GUIDES_NEW =
  "com.ellis.itrackbites.allfoodguidesnew";
export const PRODUCT_ID_RESTAURANT = "com.ellis.itrackbites.restaurantguide";
export const PRODUCT_ID_SNACK = "com.ellis.itrackbites.snackguide";
export const PRODUCT_ID_BEER = "com.ellis.itrackbites.beerguide";
export const PRODUCT_ID_ONLINE = "com.ellis.itrackbites.onlineguide";
export const PRODUCT_ID_ONLINE_UPGRADE =
  "com.ellis.itrackbites.onlineguideupgrade";
export const PRODUCT_ID_RECIPE_BUILDER = "com.ellis.itrackbites.recipebuilder";

export const S3_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://s3.us-east-2.amazonaws.com/prod.itrackbites.com/"
    : "https://s3.us-east-2.amazonaws.com/static.itrackbites.com/";
export const BUCKET_URL =
  process.env.REACT_APP_ENV === "production"
    ? "prod.itrackbites.com"
    : "static.itrackbites.com";

export const STRIPE_KEY =
  process.env.REACT_APP_ENV === "production"
    ? "pk_live_y5UzASOcqRHor6gsdGeGI5mD00HC4Dp3Pr"
    : "pk_test_MOjBtAxS1r3aMmfnOlixsNSD00KboQD0vI";

export const FALLBACK_STRIPE_PRODUCT_ID = "com.ellis.itrackbites.yearly.45";

export const AWS_CONFIG = {
  Auth: {
    identityPoolId: "us-east-1:287e1eeb-91aa-464a-a709-409898f019dd", //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: BUCKET_URL, //REQUIRED -  Amazon S3 bucket
      region: "us-east-2", //OPTIONAL -  Amazon service region
    },
  },
};
