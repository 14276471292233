import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Check(props) {
  return (
    <SvgIcon
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="#9DBF49" />
      <path
        d="M12.933 5.54245C13.0223 5.45269 13.0223 5.30717 12.933 5.21741L12.2861 4.56732C12.1967 4.47756 12.0519 4.47756 11.9626 4.56732L6.78697 9.76802C6.69764 9.85778 6.55282 9.85778 6.46349 9.76802L4.03742 7.33019C3.9481 7.24043 3.80327 7.24044 3.71395 7.33019L3.06699 7.98028C2.97767 8.07004 2.97767 8.21557 3.06699 8.30533L5.97828 11.2307C6.33558 11.5898 6.91488 11.5898 7.27218 11.2307L12.933 5.54245Z"
        fill="white"
      />
    </SvgIcon>
  );
}
