import React from "react";
import { Box, Button } from "@material-ui/core";
import { getPriceString, getPriceCopyFromStripePlan } from "helpers/format";
import { map, get } from "lodash-es";
import { appStore } from "models";
import { NavLink } from "react-router-dom";

interface Props {
  handleCreateSubscription: any;
  isModal?: boolean;
}

const moreAboutProFeatures = [
  {
    group: "More Plans",
    features: [
      {
        name: "Better Balance Plan",
        description:
          "Prioritize quality over quantity with an emphasis on fresh, clean eating.",
        icon: "balance",
      },
      {
        name: "Keto Balance Plan",
        description:
          "Opt for a low-carb, high-fat diet to accelerate fat burn.",
        icon: "keto",
      },
    ],
  },
  {
    group: "More Tracking",
    features: [
      {
        name: "Voice Search",
        description: "Simply state the food and meal - we’ll do the rest.",
        icon: "voice",
      },
      {
        name: "Macronutrient Tracking",
        description: "Track your carb, protein, and fat intake per meal.",
        icon: "track",
      },
      {
        name: "Recipe Builder",
        description: "Create your own custom recipes.",
        icon: "recipe",
      },
      {
        name: "Fitbit Step Tracking",
        description: "Automate activity tracking with Fitbit integration.",
        icon: "fitbit",
      },
      {
        name: "Export Logs",
        description: "Download detailed stats to analyze and share.",
        icon: "export-log",
      },
    ],
  },
  {
    group: "More Resources",
    features: [
      {
        name: "Recipe Database",
        description: "Browse 500K+ recipes to find your perfect meal.",
        icon: "recipe",
      },
      {
        name: "Restaurant Guide",
        description: "Make healthy decisions on what you eat while eating out.",
        icon: "restaurant",
      },
      {
        name: "Snack & Beer Guides",
        description: "Search our database of 1M+ brand-named snacks and beers.",
        icon: "snack",
      },
    ],
  },
  {
    group: "More Support",
    features: [
      {
        name: "Community Posting",
        description: "Engage with an active online community of members.",
        icon: "community",
      },
      {
        name: "Speak to Coaches",
        description:
          "Reach out to coaches any time for questions, support, and motivation.",
        icon: "coaches",
      },
      {
        name: "Engage with Groups",
        description:
          "Groups provide an additional support system on your journey.",
        icon: "engage",
      },
    ],
  },
];

const LeanMoreAboutPro = ({ handleCreateSubscription, isModal }: Props) => {
  const promoCode = get(appStore, "promo.code");

  return (
    <Box className="content-box learn-more-about-pro">
      <div className="learn-more-about-pro-header">
        <div className="learn-more-about-title">
          Learn more about <div className="pro-badge">PRO</div>
        </div>
        <div className="learn-more-about-sub-title">
          The PRO subscription provides all the tools and resources needed to
          maximize your goals.
        </div>
      </div>
      <div className="learn-more-about-feature-list">
        {map(moreAboutProFeatures, ({ group, features }, index) => (
          <div className="features-group" key={index}>
            <div className="feature-group-title">{group}</div>
            {map(features, ({ name, icon, description }) => (
              <div className="feature-item" key={name}>
                <img
                  className="feature-icon"
                  src={`images/more-about-pro/${icon}.svg`}
                  alt=""
                />
                <div className="feature-right">
                  <div className="feature-name">{name}</div>
                  <div className="feature-description">{description}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="learn-more-about-pro-bottom">
        <div className="learn-more-about-bottom-text">
          {getPriceCopyFromStripePlan(appStore.stripePlans)}
        </div>
        <Button
          onClick={handleCreateSubscription}
          className="btn-upgrade-bottom btn-red"
          variant="contained"
          color="primary"
        >
          Upgrade - {getPriceString(appStore.activeStripePlan)}/
          {get(appStore.activeStripePlan, "interval", "")}
        </Button>
        {!promoCode && !isModal && (
          <NavLink to="/promo-code">
            <div className="promo-code-bottom">Enter Promo Code</div>
          </NavLink>
        )}
        {promoCode && !isModal && (
          <div className="promo-code-bottom">
            Promo Code ({promoCode.toUpperCase()} applied)
          </div>
        )}
      </div>
    </Box>
  );
};

export default LeanMoreAboutPro;
