import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function IconCross(props) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="#BCBEC0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4441 6.03354C11.8516 5.62603 11.8516 4.96534 11.4441 4.55784C11.0366 4.15033 10.3759 4.15033 9.96837 4.55783L8.00003 6.52617L6.03118 4.55731C5.62368 4.14981 4.96298 4.14981 4.55548 4.55731C4.14798 4.96482 4.14797 5.62551 4.55548 6.03301L6.52433 8.00187L4.55746 9.96874C4.14996 10.3762 4.14996 11.0369 4.55746 11.4444C4.96496 11.8519 5.62566 11.8519 6.03316 11.4444L8.00003 9.47757L9.96638 11.4439C10.3739 11.8514 11.0346 11.8514 11.4421 11.4439C11.8496 11.0364 11.8496 10.3757 11.4421 9.96822L9.47573 8.00187L11.4441 6.03354Z"
        fill="white"
      />
    </SvgIcon>
  );
}
