import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const NotFound = () => {
  const history = useHistory();
  history.push("/home");
  const { enqueueSnackbar } = useSnackbar();
  enqueueSnackbar(
    `404: The page you are looking for isn't here. Redirecting to home...`,
    {
      variant: "error",
    },
  );
  return null;
};

export default NotFound;
