import MuiButton from "./MuiButton";
import MuiDialogActions from "./MuiDialogActions";
import MuiDialogContent from "./MuiDialogContent";
import MuiFormLabel from "./MuiFormLabel";
import MuiIconButton from "./MuiIconButton";
import MuiInputLabel from "./MuiInputLabel";
import MuiFormControl from "./MuiFormControl";
import MuiPaper from "./MuiPaper";
import MuiSelect from "./MuiSelect";
import MuiTabs from "./MuiTabs";
import MuiTableCell from "./MuiTableCell";
import MuiTableHead from "./MuiTableHead";
import MuiTypography from "./MuiTypography";
import MuiAppBar from "./MuiAppBar";
import MuiInputBase from "./MuiInputBase";
import MuiFormHelperText from "./MuiFormHelperText";
import MuiMenuItem from "./MuiMenuItem";
import MuiListItem from "./MuiListItem";

const overrides = {
  MuiButton,
  MuiDialogActions,
  MuiDialogContent,
  MuiFormLabel,
  MuiFormControl,
  MuiIconButton,
  MuiInputBase,
  MuiInputLabel,
  MuiPaper,
  MuiSelect,
  MuiTabs,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiAppBar,
  MuiFormHelperText,
  MuiMenuItem,
  MuiListItem,
};

export default overrides;
