const GOOGLE_GA_ID = "G-D8VV9PG3JN";
const GOOGLE_ADWORDS_ID = "AW-822132319";

class GoogleGA {
  static logPage = () => {
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', GOOGLE_GA_ID);
  };

  static logEvent = (name, data = null) => {
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', GOOGLE_GA_ID);
    (window as any).gtag('event', name);
  };

  static pageView = () => {
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', GOOGLE_ADWORDS_ID);
    (window as any).gtag('event', 'page_view', {
      'send_to': 'AW-822132319',
      'items': [{
        'google_business_vertical': 'retail'
      }]
    });
  }

  static addToCart = (currency, amount) => {
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', GOOGLE_ADWORDS_ID);
    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-822132319/20eSCJbXoOYDEN_8gogD',
      'value': amount,
      'currency': currency
    });
  };

  static logPurchase = (currency, amount) => {
    (window as any).gtag('js', new Date());
    (window as any).gtag('config', GOOGLE_ADWORDS_ID);
    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-822132319/UbIOCM6YouUDEN_8gogD',
      'value': amount,
      'currency': currency
      // 'transaction_id': ''
    });
  };
}

export default GoogleGA;
