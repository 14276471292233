import React, { useState } from "react";
import { IconButton, Button } from "@material-ui/core";
import IconClose from "@material-ui/icons/Close";
import ProFeatureTable from "./ProFeatureTable";
import LoadingButton from "components/LoadingButton";
import { CustomModalComponentProps } from "components/Dialog/DialogProvider";

interface Props extends CustomModalComponentProps {}

const ConfirmCancelSubscriptionModal = ({
  handleCancel,
  handleConfirm,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const handleConfirmClick = () => {
    setLoading(true);
    handleConfirm();
  };
  return (
    <div className="modal-content confirm-cancel-modal-content">
      <IconButton className="btn-close-modal" onClick={handleCancel}>
        <IconClose></IconClose>
      </IconButton>
      <div className="modal-title">Cancel Subscription</div>
      <div className="modal-sub-title">
        Without PRO you will not have access to the following features:
      </div>
      <ProFeatureTable />
      <div className="modal-ctas">
        <LoadingButton
          loading={loading}
          className="btn btn-cancel-sub"
          variant="contained"
          type="button"
          onClick={handleConfirmClick}
        >
          cancel subscription
        </LoadingButton>
        <Button
          className="btn btn-stay-pro"
          type="button"
          variant="contained"
          color="primary"
          onClick={handleCancel}
        >
          stay pro
        </Button>
      </div>
    </div>
  );
};

export default ConfirmCancelSubscriptionModal;
