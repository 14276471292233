import React, { useState } from "react";
import { CustomModalComponentProps } from "components/Dialog/DialogProvider";
import { getPlan } from "config/plans";
import PlanCard from "components/PlanCard";
import LoadingButton from "components/LoadingButton";
import { useSnackbar } from "notistack";
import IconClose from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { useChangeUserPlan } from "models/actions";
import { observer } from "mobx-react";
import { appStore } from "models";

interface Props extends CustomModalComponentProps {
  lossPlan?: number;
}

const ConfirmPlanModal: React.FC<Props> = ({
  handleCancel,
  handleConfirm,
  lossPlan = 0,
}) => {
  const currentUser = appStore.currentUser;
  const plan = getPlan(lossPlan);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const changeUserPlan = useChangeUserPlan();
  const handleChangePlan = () => {
    setLoading(true);
    changeUserPlan(plan.lossPlan)
      .then(() => {
        enqueueSnackbar(`Successfully changed your plan to ${plan.title}.`, {
          variant: "success",
        });
        handleConfirm();
      })
      .catch((error) => {
        if (error && error.message) {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="modal-content change-plan-modal">
      <IconButton className="btn-close-modal" onClick={handleCancel}>
        <IconClose></IconClose>
      </IconButton>
      <div className="modal-title">
        Would you like to change your plan to <strong>{plan.title}</strong>?
      </div>
      {plan.isPro && !currentUser.isProUser && (
        <div className="modal-description">
          By selecting this plan you will be prompted to upgrade to PRO.
        </div>
      )}
      <div className="modal-card">
        <PlanCard lossPlan={lossPlan}></PlanCard>
      </div>
      <LoadingButton
        className="btn-change-plan"
        loading={loading}
        type="button"
        variant="contained"
        color="primary"
        onClick={handleChangePlan}
      >
        SELECT PLAN
      </LoadingButton>
    </div>
  );
};

export default observer(ConfirmPlanModal);
