export default {
  root: {
    "label + &": {},
    marginTop: 24,
    fontSize: 14,
    height: 44,
    color: "#323555",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    width: "100%",
    background: "#F7F8FA",
    borderRadius: 8,
    position: "relative",
    fontFamily: [
      "Avenir LT Std",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  input: {
    height: "100%",
    width: "100%",
    background: "#F7F8FA",
    borderRadius: 8,
    position: "relative",
    padding: "10px 19px!important",
    fontFamily: [
      "Avenir LT Std",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main,
    },
  },
};
