import React, { useState } from "react";
import { Grid, Box, InputAdornment, IconButton } from "@material-ui/core";
import * as yup from "yup";
import { useForm, OnSubmit } from "react-hook-form";
import LoadingButton from "components/LoadingButton";
import { applyPromoCode } from "api/Auth";
import { Promo } from "interfaces/plan.interface";
import { useSnackbar } from "notistack";
import "./index.scss";
import { appStore } from "models";
//import ConfirmPlanModal from "./ConfirmPlanModal";
import { observer } from "mobx-react";
import { get } from "lodash-es";
import { shouldDisabledButton } from "helpers/common";
import { useHistory } from "react-router-dom";
import Amplitude from "amplitude-js";
import AdornmentInput from "components/AdornmentInput";
import IconClose from "@material-ui/icons/Close";
import Appboy from "appboy-web-sdk";

interface Props {}

const PromoCodeSchema = yup.object().shape({
  code: yup.string().required(),
});

const PromoCode: React.FC<Props> = () => {
  const currentUser = appStore.currentUser;
  const isPro = currentUser.isProUser;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  if (isPro) {
    history.push("/home");
  }

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    mode: "onBlur",
    validationSchema: PromoCodeSchema,
    defaultValues: { code: "" },
  });

  const code = appStore.currentPromoCode();
  const disabled = shouldDisabledButton(watch, errors) || code;

  const clearPromoCode = () => {
    appStore.removePromo();

    setValue("code", null);

    Amplitude.logEvent("Promo Code Removed");

    enqueueSnackbar(`Promo code removed.`, {
      variant: "success",
    });
  };
  /* eslint-disable @typescript-eslint/ban-types */
  const onSubmit: OnSubmit<{}> = async (data: any) => {
    setLoading(true);
    applyPromoCode(data.code)
      .then((resp) => {
        const promo = resp as Promo;
        const code = appStore.currentPromoCode();
        appStore.setPromo(promo);

        Amplitude.logEvent("Upgrade: Promo Code Added", {
          "Promo Code": code,
        });

        Appboy.logCustomEvent("Upgrade: Promo Code Added", {
          "Promo Code": code,
        });

        enqueueSnackbar(`Promo code applied.`, {
          variant: "success",
        });

        history.push("/subscription");
      })
      .catch((error) => {
        if (error && error.message) {
          error.message = error.message.substring(
            error.message.indexOf(":") + 2,
          );
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      })
      .finally(() => setLoading(false));
  };

  Amplitude.logEvent("Screen View: Add Promo Code");

  return (
    <Grid className="page-edit-profile" spacing={6} container>
      <Grid xs={12} item>
        <Box className="content-box edit-profile-box">
          <div className="box-title">Promo Code</div>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={6} className="profile-form">
              <Grid xs={12} md={6} item>
                <AdornmentInput
                  inputRef={register}
                  error={!!errors.code}
                  helperText={get(errors, "code.message")}
                  type="text"
                  id="code"
                  label="Code"
                  name="code"
                  value={code}
                  disabled={!!code}
                  placeholder="Enter your code"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Remove code"
                        onClick={clearPromoCode}
                        edge="end"
                      >
                        {<IconClose />}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                  autoFocus
                />
              </Grid>
            </Grid>
          </form>
          <Grid xs={12} item className="actions-row">
            <LoadingButton
              className="btn-submit-profile-form"
              loading={loading}
              type="button"
              variant="contained"
              color="primary"
              disabled={disabled}
              onClick={handleSubmit(onSubmit)}
            >
              Apply
            </LoadingButton>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default observer(PromoCode);
