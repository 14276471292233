import React, { ReactNode, useCallback } from "react";
import {
  Box,
  Container,
  Card,
  Button,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import BackArrow from "icons/back";
import clsx from "clsx";
import "./index.scss";

interface Props {
  children: ReactNode;
  onBackClick?: () => void;
  style?: CSSProperties;
  className?: string;
  hideBack?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    width: "100%",
  },
  content: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  paper: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "78px",
    paddingBottom: "100px",
    boxShadow: "2px 16px 40px rgba(0, 12, 45, 0.05)",
    borderRadius: "20px",
    width: "571px",
    position: "relative",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "290px",
  },
  login: {
    margin: theme.spacing(2, 0, 1),
  },
  continueWithGoogle: {
    margin: theme.spacing(2, 0, 1),
    paddingLeft: 44,
    textTransform: "none",
  },
  input: {
    margin: "0px 0px 10px 0px",
  },
  backButton: {
    height: 48,
    padding: "6px 12px",
    position: "absolute",
    top: 20,
    left: 15,
    color: "#959FAF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  backIcon: {
    height: 24,
    width: 24,
    marginRight: 8,
    color: "white",
  },
}));

const SimpleCardPage: React.FC<Props> = ({
  children,
  onBackClick,
  style,
  className,
  hideBack,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const handleClickBack = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <>
      <Box className={classes.container}>
        <Container
          component="main"
          className={clsx(
            classes.content,
            className,
            "simple-card-page-paper-wrapper",
          )}
        >
          <Card
            className={clsx(classes.paper, "simple-card-page-paper")}
            style={style}
          >
            {!hideBack && (
              <Button
                onClick={onBackClick || handleClickBack}
                className={classes.backButton}
              >
                <BackArrow className={classes.backIcon} />
                Back
              </Button>
            )}
            {children}
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default SimpleCardPage;
