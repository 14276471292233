import React, { useEffect } from "react";
import { Grid, Box, Button, Typography, Link } from "@material-ui/core";
import "./index.scss";
import { observer } from "mobx-react";
import { appStore } from "models";
import useDialog from "components/Dialog/useDialog";
import { cancelSubscription } from "api/Checkout";
import { get, capitalize, noop } from "lodash-es";
import {
  getPriceString,
  getOriginPriceString,
  safeDateFormat,
} from "helpers/format";
import ProBadgeLg from "icons/pro-badge-lg";
import { PaymentMethod } from "config/enum";
import ProFeatureTable from "./ProFeatureTable";
import clsx from "clsx";
import ConfirmCancelSubscriptionModal from "./ConfirmCancelSubscriptionModal";
import { useCreateSubscription, syncUserInfo } from "models/actions";
import UpdatePaymentMethod from "views/CheckoutModal/UpdatePaymentMethod";
import LeanMoreAboutPro from "./LeanMoreAboutPro";
import Amplitude from "amplitude-js";
import { NavLink, useHistory } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

interface Props {}

function getCardBrandName(brand) {
  const nameMap = {
    amex: "American Express",
    mastercard: "Mastercard",
    visa: "Visa",
  };
  return nameMap[brand] || capitalize(brand);
}

const ManageSubscription: React.FC<Props> = () => {
  const history = useHistory();
  const currentUser = appStore.currentUser;
  const isPro = currentUser.isProUser;
  const { openDialog } = useDialog();
  const handleCreateSubscription = useCreateSubscription();
  let subscriptionCancellationDate = get(
    currentUser,
    "subscription.subscriptionCancellationDate",
  );
  const subscriptionExpirationDate = get(
    currentUser,
    "subscription.subscriptionExpirationDate",
  );

  const subscriptionRenewalAmount = get(
    currentUser,
    "subscription.stripe.subscriptions.data[0].items.data[0].plan.amount_decimal",
  );

  const subscriptionRenewalAmountDisplay = subscriptionRenewalAmount ? "$" + subscriptionRenewalAmount / 100 : "";

  const promoCode = get(appStore, "promo.code");
  const paymentMethod = Number(get(currentUser, "subscription.paymentMethod"));
  const canCancelOnWeb = paymentMethod === PaymentMethod.STRIPE;
  const paymentMethodDetail = get(currentUser, "subscription.payment_method");
  const showPaymentMethod =
    [PaymentMethod.ANDROID, PaymentMethod.APPLE].indexOf(paymentMethod) > -1 ||
    (paymentMethod === PaymentMethod.STRIPE && !!paymentMethodDetail);
  const paymentMethodProp = {
    text: "" as any,
    buttonEnable: false,
    className: "",
    icon: "",
  };

  if (paymentMethod === PaymentMethod.APPLE) {
    paymentMethodProp.text = (
      <>
        You subscribed via the App Store. Manage your subscription&nbsp;
        <Link href="https://support.apple.com/en-us/HT202039" target="_blank">
          here
        </Link>
        .
      </>
    );
    paymentMethodProp.icon = "apple";
  } else if (paymentMethod === PaymentMethod.ANDROID) {
    paymentMethodProp.text = (
      <>
        You subscribed via the Google Play Store. Manage your subscription&nbsp;
        <Link
          href="https://support.google.com/googleplay/answer/7018481"
          target="_blank"
        >
          here
        </Link>
        .
      </>
    );
    paymentMethodProp.icon = "google-play";
  } else if (paymentMethod === PaymentMethod.STRIPE) {
    paymentMethodProp.className = "can-update-payment-method";
    paymentMethodProp.buttonEnable = true;
    paymentMethodProp.text = "Update payment method";

    if (!subscriptionCancellationDate) {
      subscriptionCancellationDate = appStore.stripeSubscriptionExpired;
    }
  }

  const handleCancelSubscription = () => {
    openDialog({
      component: ConfirmCancelSubscriptionModal,
      isAsync: true,
    })
      .then(async ({ result, resolve }) => {
        if (result) {
          await cancelSubscription();
          await syncUserInfo(true);
        }
        resolve();
      })
      .catch(noop);
  };
  const handleClickUpdatePaymentMethod = () => {
    openDialog({
      component: UpdatePaymentMethod,
      className: "update-payment-modal",
    })
      .then(async (result) => {
        if (result) {
          console.error("result", result);
        }
      })
      .catch(noop);
  };
  const handleNavigatePromoCode = () => {
    history.push("/promo-code");
  };

  const amount = get(appStore.activeStripePlan, "amount", 0) / 100;
  const productId = get(appStore.activeStripePlan, "metadata.productid", "");
  const code = appStore.currentPromoCode();

  Amplitude.logEvent("Page View: Subscribe", {
    Price: amount,
    "Product ID": productId,
    "Promo Code": code,
    Source: "web",
  });

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("productId");
    };
  }, []);

  return (
    <Grid className="page-manage-subscription" spacing={6} container>
      <Grid xs={12} item>
        <Box className="content-box">
          <div className="box-sub-title">Current Subscription</div>
          {isPro ? (
            <>
              <ProBadgeLg className="membership-pro-badge" />
              <div
                className={clsx("membership-discription", {
                  "mt-20": subscriptionCancellationDate,
                })}
              >
                {!subscriptionCancellationDate
                  ? subscriptionExpirationDate
                    ? `Your subscription is active and your next renewal will be ${subscriptionRenewalAmountDisplay} on ${safeDateFormat(
                        subscriptionExpirationDate,
                        "MMM do, yyyy",
                      )}.`
                    : "Your subscription is currently active."
                  : `Renewal of your subscription has been canceled. You can still access PRO through ${safeDateFormat(
                      subscriptionExpirationDate,
                      "MMM do, yyyy",
                    )}.`}
              </div>
              {!subscriptionCancellationDate && (
                <Button
                  className="btn-membership-cta cancel-sub"
                  disabled={!canCancelOnWeb}
                  onClick={handleCancelSubscription}
                >
                  {canCancelOnWeb
                    ? "Cancel Subscription"
                    : subscriptionExpirationDate
                    ? "Go to the Apple App Store or Google Play Store to access your profile to cancel through Subscription Management."
                    : "Manage your subscription on your mobile device in the App Store or Google Play Store."}
                </Button>
              )}
            </>
          ) : (
            <>
              <Typography variant="h2" className="membership-title">
                Basic
              </Typography>
              <Typography className="membership-discription">
                Supercharge your weight loss with unlimited access to our most
                popular PRO features.
              </Typography>
              <Grid className="" spacing={1} container>
                <Grid xs={3} item>
                  <Button
                    onClick={handleCreateSubscription}
                    className="btn-upgrade btn-red"
                    variant="contained"
                    color="primary"
                  >
                    Upgrade Now
                  </Button>
                  {!promoCode && (
                    <NavLink to="/promo-code">
                      <div className="content-box-link promo-code">
                        Enter Promo Code
                      </div>
                    </NavLink>
                  )}
                </Grid>
                <Grid xs={3} item>
                  {promoCode && (
                    <Button
                      onClick={handleNavigatePromoCode}
                      className="btn-promo"
                      variant="contained"
                      color="primary"
                    >
                      {promoCode.toUpperCase()}
                      <NavigateNextIcon className="arrow" />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Grid>
      {isPro && showPaymentMethod && (
        <Grid xs={12} item>
          <Box className="content-box payment-method">
            <div className="box-sub-title">Payment method</div>
            {paymentMethod === PaymentMethod.STRIPE && (
              <>
                <div className="mastercard-info">
                  {`${getCardBrandName(
                    get(paymentMethodDetail, "card.brand"),
                  )} ending in ${get(paymentMethodDetail, "card.last4")}`}
                  <img
                    className="card-brand"
                    src={`/images/${get(
                      paymentMethodDetail,
                      "card.brand",
                    )}.png`}
                    alt={get(paymentMethodDetail, "card.brand")}
                  />
                </div>
                <div className="expire-date">
                  {`Expiration Date: ${safeDateFormat(
                    new Date(
                      get(paymentMethodDetail, "card.exp_year"),
                      get(paymentMethodDetail, "card.exp_month") - 1,
                    ),
                    "MM / yyyy",
                  )}`}
                </div>
              </>
            )}
            {paymentMethodProp.icon && (
              <img
                className="payment-method-icon"
                src={`/images/payment-methods/${paymentMethodProp.icon}.png`}
                alt={paymentMethodProp.icon}
              ></img>
            )}
            {paymentMethodProp.buttonEnable ? (
              <Button
                className={`btn-membership-cta update-payment-method ${paymentMethodProp.className}`}
                onClick={handleClickUpdatePaymentMethod}
              >
                {paymentMethodProp.text}
              </Button>
            ) : (
              <div
                className={`way-to-change-payment-method update-payment-method ${paymentMethodProp.className}`}
              >
                {paymentMethodProp.text}
              </div>
            )}
          </Box>
        </Grid>
      )}
      {!isPro && (
        <>
          <Grid xs={12} item>
            <Box className="content-box become-a-pro">
              <div className="become-a-pro-title">
                Become a <div className="pro-badge">PRO</div>
              </div>
              <div className="become-a-pro-sub-title">
                Save&nbsp;
                {100 - get(appStore, "activeStripePlan.metadata.discount", 100)}
                % off regular PRO pricing
              </div>
              <div className="become-a-pro-price">
                <div className="original-price">
                  {getOriginPriceString(appStore.activeStripePlan)}
                </div>
                <div className="current-price">
                  {getPriceString(appStore.activeStripePlan)}
                </div>
              </div>
              <ProFeatureTable handleClickUpgrade={handleCreateSubscription} />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <LeanMoreAboutPro
              handleCreateSubscription={handleCreateSubscription}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default observer(ManageSubscription);
