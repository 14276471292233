import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { appStore } from "models";
import Storage from "@aws-amplify/storage";
import { uuidv4 } from "helpers/uuid";
import { S3_URL } from "config";
import { useSnackbar } from "notistack";
import { updateUserInfo } from "api/Auth";
import { get } from "lodash-es";
import LoadingButton from "components/LoadingButton";

interface Props {}

const EditAvatar = (props: Props) => {
  const currentUser = appStore.currentUser;
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setAvatarFile(null);
  }, [currentUser.profilePhotoUrl]);
  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files![0];
    if (file) {
      setAvatarFile(file);
      event.currentTarget.value = "";
    }
  };
  const onRemove = async () => {
    await Promise.all([
      Storage.remove(currentUser.profilePhotoUrl),
      updateUserInfo({
        profilePhotoUrl: "",
      }),
    ]);
  };
  const handleClickEditAvatar = () => {
    setEditing(true);
  };
  const handleClickCancel = () => {
    setEditing(false);
  };
  const onSubmit = async () => {
    if (avatarFile) {
      try {
        setLoading(true);
        const remotePath = "photo/profile/raw/";
        const customPrefix = {
          public: remotePath,
        };
        if (currentUser.profilePhotoUrl) {
          await Storage.remove(currentUser.profilePhotoUrl);
        }
        const result: any = await Storage.put(
          uuidv4() + avatarFile.name,
          avatarFile,
          {
            customPrefix: customPrefix,
          },
        );
        if (result) {
          await updateUserInfo({
            profilePhotoUrl: S3_URL + remotePath + result.key,
          });
        }
        enqueueSnackbar(`Avatar updated.`, {
          variant: "success",
        });
        setLoading(false);
        setEditing(false);
      } catch (error) {
        console.error("error", error);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      }
    }
  };
  return (
    <div className="avatar-area">
      <Avatar
        alt={currentUser.name}
        className="user-avatar"
        src={
          avatarFile
            ? URL.createObjectURL(avatarFile)
            : currentUser.profilePhotoUrl
        }
      >
        <img
          src="/images/default-avatar.svg"
          className="user-avatar-fallback"
          alt=""
        />
      </Avatar>
      <input
        accept=".jpg,.png"
        style={{ display: "none" }}
        id="avatarFileInput"
        type="file"
        onChange={onUpload}
      />
      <div className="upload-file-form">
        {editing ? (
          <>
            <div
              className="content-box-link secondary"
              onClick={handleClickCancel}
            >
              Cancel
            </div>
            <div className="upload-file-info">
              <label htmlFor="avatarFileInput">
                <div className="content-box-link">Choose File</div>
              </label>
              <div className="file-name">
                {get(avatarFile, "name") || "No file chosen"}
              </div>
            </div>
            <div className="supported-type">JPG, PNG</div>
            <LoadingButton
              className="btn-upload btn-red btn-sm"
              loading={loading}
              type="button"
              variant="contained"
              color="primary"
              disabled={!get(avatarFile, "name")}
              onClick={onSubmit}
            >
              Upload
            </LoadingButton>
          </>
        ) : (
          <>
            <div
              className="content-box-link btn-edit-profile"
              onClick={handleClickEditAvatar}
            >
              Change profile picture
            </div>
            {currentUser.profilePhotoUrl && (
              <div
                className="content-box-link secondary btn-delete"
                onClick={onRemove}
              >
                Delete
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EditAvatar;
