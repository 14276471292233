import { HeightUnit, WeightUnit } from "config/enum";
import { getInches, getLbs } from "./calculate";

export const validateHeight = (getCurrentWatchRef) =>
  function (height) {
    const { heightUnit } = getCurrentWatchRef();
    const error = { path: this.path, message: "" };
    if (heightUnit === HeightUnit.CENTIMETERS) {
      if (height < 100 || height > 225.9) {
        error.message = "Height should between 100 and 225.9 cm";
      }
    } else if (heightUnit === HeightUnit.FEET_AND_INCHES) {
      const inches = getInches(height, heightUnit);
      if (inches < 48 || inches > 95) {
        error.message = "Height should between 4 ft 0 in and 7 ft 11 in";
      }
    } else if (heightUnit === HeightUnit.INCHES) {
      const inches = getInches(height, heightUnit);
      if (inches < 40 || inches > 90.9) {
        error.message = "Height should between 40 and 90.9 inches";
      }
    }
    return error.message ? this.createError(error) : true;
  };

export const validateWeight = (getCurrentWatchRef) =>
  function (weight) {
    const { weightUnit } = getCurrentWatchRef();
    const error = { path: this.path, message: "" };
    if (weightUnit === WeightUnit.POUNDS) {
      if (weight < 50 || weight > 800.9) {
        error.message = "Weight should between 50 and 800.9 lbs";
      }
    } else if (weightUnit === WeightUnit.KILOGRAMS) {
      if (weight < 25 || weight > 250.9) {
        error.message = "Weight should between 25 and 250.9 kgs";
      }
    } else if (weightUnit === WeightUnit.STONES) {
      const weightLbs = getLbs(weight, weightUnit);
      if (weightLbs < 70 || weightLbs > 503) {
        error.message = "Weight should between 5 st 0 lbs and 35st 13 lbs";
      }
    }
    return error.message ? this.createError(error) : true;
  };
