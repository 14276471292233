import React, { useLayoutEffect, ReactElement } from "react";
import { render, unmountComponentAtNode } from "react-dom";

type Props = {
  children: ReactElement;
};

/**
 * a component wrapper used to get the child components to render in the document.body el.
 * this is now used to let the componet with "position:fiexd" to render above the component like modal
 */
const RenderInBody: React.FC<Props> = ({ children }) => {
  useLayoutEffect(() => {
    const popup = document.createElement("div");
    document.body.appendChild(popup);
    render(children, popup);
    return () => {
      unmountComponentAtNode(popup);
      document.body.removeChild(popup);
    };
  }, [children]);
  return null;
};

RenderInBody.propTypes = {};

export default RenderInBody;
