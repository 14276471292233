/**
 * Http.ts
 *
 * Axios configuration for headers and request/response interceptors
 */
import axios from "axios";
import { getAuthId, getAuthSecret, clearUserInfo } from "helpers/authstorage";
import { UAParser } from "ua-parser-js";
import { API_VERSION } from "config";
import { Base64 } from "js-base64";
import { get } from "lodash-es";

const env: string = process.env.REACT_APP_ENV;

const prefix =
  env === "production"
    ? "api"
    : env === "staging"
    ? "staging-api"
    : "development-api";

const url =
  prefix === "development-api"
    ? `http://localhost/api/v2`
    : `https://${prefix}.itrackbites.com/api/v2`;

// base url for api requests
const http = axios.create({
  baseURL: url,
});

// UA
const result = new UAParser().getResult();

// capture the last url in the event of a response error
let lastUrl: string | undefined;

http.interceptors.request.use(
  // request successful
  function (config) {
    // set the last url in the event of a response error
    lastUrl = config.url;

    const authId = getAuthId();
    const authSecret = getAuthSecret();

    if (authId && authSecret) {
      const credentials = `${authId}:${authSecret}`;
      const basic = Base64.encode(credentials);
      config.headers["Authorization"] = `Basic ${basic}`;
    }

    config.headers["ITB-VERSION"] = API_VERSION;
    config.headers["ITB-DEVICE"] = result.browser.name;
    config.headers["ITB-PLATFORM"] = "WEB";
    config.headers["Content-Type"] = "application/json";
    try {
      config.headers["ITB-TIMEZONE"] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {}

    return config;
  },

  // request error
  function (error) {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  // response successful
  function (response) {
    return response;
  },

  // response error so publish the error for subscribers to consume
  function (error) {
    if (get(error, "response.data.error.code") === 400 ||
        get(error, "response.data.error.code") === 409
    ) { 
      console.log("API_LOG", error.response);
    } else {
      console.error("API_ERROR", error.response);
    }

    if (
      get(error, "response.data.error.code") === 401 ||
      get(error, "response.data.error.code") === 410
    ) {
      clearUserInfo();
      if (window.location.pathname !== "/login") {
        window.location.pathname = "/login";
      }
    }

    return Promise.reject({
      status: get(error, "response.data.error.code") || get(error, "status"),
      statusText:
        get(error, "response.data.error.statusText") ||
        get(error, "statusText", ""),
      message: get(error, "response.data.error.message", ""),
      url: lastUrl,
    });
  },
);

export default http;
