/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import IconClose from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { appStore } from "models";
import PaymentMethodForm from "./PaymentMethodForm";
import { createStripeCustomer, deleteStripeCustomer } from "api/Checkout";
import { syncUserInfo } from "models/actions";
import { get } from "lodash-es";
import useDialog from "components/Dialog/useDialog";
import { useSnackbar } from "notistack";
import { STRIPE_KEY } from "config";

const UpdatePaymentMethod = ({ handleCancel, handleConfirm }) => {
  const currentUser = appStore.currentUser;
  const handleSubmitPaymentMethod = async (paymentMethod) => {
    try {
      await createStripeCustomer(paymentMethod.id);
      await syncUserInfo(true);
      handleConfirm();
    } catch (error) {
      appStore.setCheckoutFormError(error);
    }
  };
  const subscriptionCancellationDate = get(
    currentUser,
    "subscription.subscriptionCancellationDate",
  );
  const { openDialog } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteMyCard = async () => {
    try {
      if (!subscriptionCancellationDate) {
        openDialog({
          subTitle: "Delete Card",
          title:
            "You must first cancel your subscription before you can delete your card.",
          cancelText: null,
          confirmText: "OK",
        });
      } else {
        const { result, resolve } = await openDialog({
          subTitle: "Delete Card",
          title: "Are you sure you want to delete your card?",
          isAsync: true,
        });
        if (result) {
          await deleteStripeCustomer();
          await syncUserInfo(true);
        }
        resolve();
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  return (
    <div className="modal-content update-payment-modal-content">
      <IconButton className="btn-close-modal" onClick={handleCancel}>
        <IconClose></IconClose>
      </IconButton>
      <div className="modal-title">Update Payment Method</div>
      <Elements stripe={loadStripe(STRIPE_KEY)}>
        <PaymentMethodForm
          handleSubmitPaymentMethod={handleSubmitPaymentMethod}
          buttonText="update"
        />
      </Elements>
      <div className="modal-bottom-link" onClick={handleDeleteMyCard}>
        Delete my credit card
      </div>
    </div>
  );
};

export default UpdatePaymentMethod;
