import React from "react";
import { useQuery } from "helpers/useHooks";
import { Redirect } from "react-router-dom";

interface Props {}

const Deeplink = (props: Props) => {
  const { r, productid, source } = useQuery();
  // store r when login, redirect it.
  if (r) {
    // store product info
    if (r === "pro/upgrade") {
      sessionStorage.setItem("r", "/subscription");
      if (productid) {
        sessionStorage.setItem("productId", String(productid));
      }
      if (source) {
        sessionStorage.setItem("source", String(source));
      }
    } else {
      // will be consume in initApp
      sessionStorage.setItem("r", String(r));
    }
  }
  return <Redirect to="/home" />;
};

export default Deeplink;
