import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SimpleCardPage from "components/SimpleCradPage";
import Check from "icons/check";
import { emailMagicLink } from "api/Auth";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { get } from "lodash-es";
import LoadingButton from "components/LoadingButton";
import { shouldDisabledButton } from "helpers/common";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "290px",
    height: "314px",
  },
  send: {
    margin: theme.spacing(4, 0, 1),
  },
  input: {
    margin: "0px 0px 10px 0px",
  },
}));

const fotgetPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export default function ForgetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const classes = useStyles();
  const { register, handleSubmit, watch, errors, setValue } = useForm({
    mode: "onBlur",
    validationSchema: fotgetPasswordSchema,
    defaultValues: { email: "" },
  });
  const onSubmit = (data: any) => {
    setIsLoading(true);
    emailMagicLink(data)
      .then((resp) => {
        setIsSent(true);
        setEmailSent(data.email);
        setValue("email", "");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const title = isSent ? "Email sent" : "Forgot your password?";
  const description = isSent
    ? `If there is an account for ${emailSent}, you will receive a reset password link.`
    : "Enter your email address, we will send you an email with password reset instructions.";

  const disabled = shouldDisabledButton(watch, errors);
  return (
    <SimpleCardPage className="page-forget-password">
      <Box className="simple-card-page-title">
        {title}
        {isSent && <Check style={{ marginLeft: 10 }} />}
      </Box>
      <Box className="simple-card-page-description">{description}</Box>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          inputRef={register}
          error={!!errors.email}
          helperText={get(errors, "email.message")}
          className={classes.input}
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <LoadingButton
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={disabled}
          className={classes.send}
        >
          Send
        </LoadingButton>
      </form>
    </SimpleCardPage>
  );
}
