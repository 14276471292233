import { format, getDay, addDays } from "date-fns";
import {
  LossPlan,
  FitnessGoal,
  HeightCategory,
  Gender,
  ActivityLevel,
  WeightUnit,
  HeightUnit,
  SecondaryMetric,
} from "config/enum";
import { User } from "interfaces/user.interface";
import { isNil, keys, intersection, assign, get } from "lodash-es";
import { toFixed } from "./math";
import { mapUserFields, cleanFields } from "models";
import { isUndefined } from "util";
import { isBitesPlan } from "config/plans";

const { round, min, max, ceil } = Math;

const LBS_IN_KG = 2.20462;
const CENTIMETERS_IN_INCH = 2.54;
const LBS_IN_STONE = 14.0;
const CALORIES_IN_POUND_FAT = 3500.0;
const INCHES_IN_FOOT = 12;

export const setInchesAndLbs = (data: any, currentUser: User) => {
  const heightUnit = isNaN(data.heightUnit)
    ? currentUser.heightUnit
    : data.heightUnit;
  const weightUnit = isNaN(data.weightUnit)
    ? currentUser.weightUnit
    : data.weightUnit;
  if (!data.heightInch && data.height) {
    data.heightInch = getInches(data.height as string, heightUnit);
  }
  if (!data.startWeightLbs && data.startWeight) {
    data.startWeightLbs = getLbs(data.startWeight as string, weightUnit);
  }
  if (!data.goalWeightLbs && data.goalWeight) {
    data.goalWeightLbs = getLbs(data.goalWeight as string, weightUnit);
  }
  return data;
};

export const calculate = (isCreate, user: any, delta?: any) => {
  const result: any = {};
  const newUser = assign({}, user, delta);

  // checkMacroAllowance
  if (newUser.lossPlan === LossPlan.KEEPING_KETO) {
    result.fatAllowancePercent = 70;
    result.carbsAllowancePercent = 5;
    result.proteinAllowancePercent = 25;
  } else {
    if (newUser.activityLevel === 3) {
      result.proteinAllowancePercent = 25;
      result.carbsAllowancePercent = 45;
    } else {
      result.proteinAllowancePercent = 20;
      result.carbsAllowancePercent = 50;
    }
    result.fatAllowancePercent = 30;
  }

  const userForCalc = assign(newUser, result);

  result.dailyAllowance = dailyAllowancePointsForPlan(userForCalc);
  result.weeklyAllowance = weeklyAllowancePointsForPlan(userForCalc);
  result.activityAllowance = activityAllowancePointsForPlan(userForCalc);
  result.caloriesAllowance = caloriesAllowancePoints(userForCalc);

  return isCreate ? assign(newUser, result) : assign(result, delta);
};

export const getCreateUser = (data: any) => {
  if (!data.lossPlan) {
    data.lossPlan = getRecommendedPlan(data.habits as number[]);
  }
  mapUserFields(setInchesAndLbs(data, {}));
  const user = {
    ...data,
    isShowIconBadge: true,
    isShowWeightProgress: true,
    startDate: format(new Date(), "yyyy-MM-dd"),
    fitnessGoal:
      data.lossPlan === LossPlan.CALORIE_COMMAND ||
      data.lossPlan === LossPlan.KEEPING_KETO
        ? FitnessGoal.LOSE_ONE_LBS_PER_WEEK
        : FitnessGoal.LOSE_HALF_LBS_PER_WEEK,
    extraAllowanceOrder: 0,
    atyAllowanceMethod: 2,
    weekStartDay: 0,
    about: null,
  };

  return cleanFields(calculate(true, user));
};

export const getUpdateUser = (
  currentUser: User,
  delta: any,
  updateOnBoarding?: boolean,
) => {
  if (
    intersection(keys(delta), [
      "lossPlan",
      "gender",
      "height",
      "startWeight",
      "goalWeight",
      "birthDate",
      "activityLevel",
      "isCcpaOptOut",
    ]).length > 0
  ) {
    // lossPlan changes update fitnessGoal
    if (
      delta.lossPlan !== undefined &&
      currentUser.lossPlan !== delta.lossPlan
    ) {
      // update isManualAllowance
      delta.isManualAllowance = false;
      // update fitnessGoal
      if (isBitesPlan(delta.lossPlan) && currentUser.fitnessGoal > 1) {
        delta.fitnessGoal = 1;
      }
      if (updateOnBoarding && !isBitesPlan(delta.lossPlan)) {
        delta.fitnessGoal = 2;
      }

      // update secondaryMetric
      if (
        delta.lossPlan !== LossPlan.CALORIE_COMMAND &&
        currentUser.secondaryMetric === SecondaryMetric.CALORIES
      ) {
        delta.secondaryMetric = SecondaryMetric.NONE;
      }
      if (!get(currentUser, "subscription.pro")) {
        delta.secondaryMetric = SecondaryMetric.NONE;
      }
      if (delta.lossPlan !== LossPlan.KEEPING_KETO) {
        delta.secondaryMetric = SecondaryMetric.MACROS;
      }
    }

    setInchesAndLbs(delta, currentUser);
    return calculate(false, currentUser, delta);
  }
  return delta;
};

export const getRecommendedPlan = (habits: number[] = []) => {
  if (habits && habits.indexOf(0) > -1 && habits.indexOf(2) === -1) {
    return LossPlan.CONQUER_CRAVINGS;
  } else {
    return LossPlan.SUGAR_SMART;
  }
};

export const dailyAllowancePointsForPlan = (user: User) => {
  const lossPlan = user.lossPlan!;
  const gender = user.gender!;
  const fitnessGoal = user.fitnessGoal!;
  const activityLevel = user.activityLevel!;
  const heightInch = user.heightInch!;
  const age = user.age!;
  const weight = (
    isNil(user.recentWeight) ? user.recentWeight : user.startWeightLbs
  )!;
  const maintainWeight = fitnessGoal === FitnessGoal.MAINTAIN;
  const carbsAllowancePercent = user.carbsAllowancePercent!;
  let daily = user.dailyAllowance;
  if (!user.isManualAllowance) {
    if (lossPlan === LossPlan.CONQUER_CRAVINGS) {
      daily = calculateDailyAllowancePointsWithGender(
        gender,
        weight,
        heightInch,
        age,
        activityLevel,
        maintainWeight,
      );
    } else if (lossPlan === LossPlan.CARB_CONSCIOUS) {
      daily = calculateDailyAllowancePointsPlus(
        gender,
        weight,
        heightInch,
        age,
        maintainWeight,
      );
    } else if (lossPlan === LossPlan.SUGAR_SMART) {
      daily = calculateDailyAllowanceSmartPoints(
        gender,
        weight,
        heightInch,
        age,
        maintainWeight,
      );
    } else if (lossPlan === LossPlan.BETTER_BALANCE) {
      daily = calculateDailyAllowanceFlexPoints(
        gender,
        weight,
        heightInch,
        age,
        maintainWeight,
      );
    } else if (lossPlan === LossPlan.CALORIE_COMMAND) {
      daily = calculateDailyAllowanceForCalories(
        gender,
        weight,
        heightInch,
        age,
        activityLevel,
        fitnessGoal,
      );
    } else if (lossPlan === LossPlan.KEEPING_KETO) {
      daily = calculateDailyAllowanceForKeto(
        gender,
        weight,
        heightInch,
        age,
        activityLevel,
        fitnessGoal,
        carbsAllowancePercent,
      );
    }
  }
  return daily;
};

export const calculateDailyAllowancePointsWithGender = (
  gender: number,
  weightLbs: number,
  heightInches: number,
  age: number,
  activityLevel: number,
  maintain: boolean,
) => {
  let heightLvl = HeightCategory.UNDER_FIVEONE;
  if (heightInches < 61.0) {
    heightLvl = HeightCategory.UNDER_FIVEONE;
  } else if (heightInches >= 61.0 && heightInches <= 70.0) {
    heightLvl = HeightCategory.FIVEONE_TO_FIVETEN;
  } else if (heightInches > 70.0) {
    heightLvl = HeightCategory.OVER_FIVETEN;
  }

  let allowedPoints = 0;

  switch (gender) {
    case Gender.FEMALE:
      allowedPoints += 2;
      break;
    case Gender.MALE:
      allowedPoints += 8;
      break;
    case Gender.NURSING_FULL_TIME:
      allowedPoints += 12;
      break;
    case Gender.NURSING_PART_TIME:
      allowedPoints += 7;
      break;
  }

  const fWeight = weightLbs * 0.1;
  allowedPoints += fWeight;

  if (17 <= age && age <= 26) {
    allowedPoints += 4;
  } else if (27 <= age && age <= 37) {
    allowedPoints += 3;
  } else if (38 <= age && age <= 47) {
    allowedPoints += 2;
  } else if (48 <= age && age <= 58) {
    allowedPoints += 1;
  }

  if (heightLvl === HeightCategory.UNDER_FIVEONE) {
    allowedPoints += 0;
  } else if (heightLvl === HeightCategory.FIVEONE_TO_FIVETEN) {
    allowedPoints += 1;
  } else if (heightLvl === HeightCategory.OVER_FIVETEN) {
    allowedPoints += 2;
  }

  allowedPoints += Number(activityLevel) * 2;
  allowedPoints += maintain ? 6 : 0;

  allowedPoints = max(min(44, allowedPoints), 18);

  return allowedPoints;
};

export const calculateDailyAllowancePointsPlus = (
  gender: number,
  weightLbs: number,
  heightInches: number,
  age: number,
  maintain: boolean,
) => {
  let daily = 0;
  const wmulti = 0.45359237;
  const hmulti = 0.0254;
  const base1 = gender === Gender.MALE ? 864.0 : 387.0;

  const age1 = gender === Gender.MALE ? 9.72 : 7.31;
  const activity = gender === Gender.MALE ? 1.12 : 1.14;
  const wgt1 = gender === Gender.MALE ? 14.2 : 10.9;
  const ht1 = gender === Gender.MALE ? 503 : 660.7;
  const totage = round(age1 * age * 100.0) / 100.0;
  const totwgt = round(wmulti * weightLbs * wgt1 * 100.0) / 100.0;
  const totht = round(hmulti * heightInches * ht1 * 100.0) / 100.0;
  const tee1 = round(activity * (totwgt + totht) * 100) / 100.0;
  const tee2 = round((base1 - totage + tee1) * 100.0) / 100.0;
  const atee1 = tee2 - tee2 * 0.1 + 200;
  const target1 = (atee1 - 1000) / 35.0;

  daily = round(target1 - 11);

  daily = max(min(daily, 71), 26);

  if (gender === Gender.NURSING_FULL_TIME) {
    daily += 14;
  } else if (gender === Gender.NURSING_PART_TIME) {
    daily += 7;
  }

  daily += maintain ? 6 : 0;

  return daily;
};

export const calculateDailyAllowanceSmartPoints = (
  gender: number,
  weightLbs: number,
  heightInches: number,
  age: number,
  maintain: boolean,
) => {
  let daily = 0;

  if (gender === Gender.MALE) {
    daily = round(
      min(
        max(heightInches * 0.654 + weightLbs * 0.19 - age * 0.2125 - 37.6, 30),
        93,
      ),
    );
  } else {
    daily = round(
      min(
        max(heightInches * 0.667 + weightLbs * 0.19 - age * 0.209 - 45.5, 30),
        93,
      ),
    );
  }
  daily += maintain ? 3 : 0;

  if (Number(gender) > 1) {
    //female
    if (gender === Gender.NURSING_FULL_TIME) {
      daily += 16;
    } else {
      daily += 6;
    }

    if (gender === Gender.NURSING_FULL_TIME) {
      daily = max(daily, 52);
    }
  }

  return daily;
};

export const calculateDailyAllowanceFlexPoints = (
  gender: number,
  weightLbs: number,
  heightInches: number,
  age: number,
  maintain: boolean,
) => {
  let allowance = calculateDailyAllowanceSmartPoints(
    gender,
    weightLbs,
    heightInches,
    age,
    maintain,
  );
  allowance = max(0.77 * allowance, 23.0);

  return allowance;
};

export const calculateDailyAllowanceForCalories = (
  gender: number,
  weightLbs: number,
  heightInches: number,
  age: number,
  atyLvl: number,
  fitnessGoal: number,
) => {
  const adjustWeight = 10 * lbsToKgs(weightLbs);
  // Convert inches to centimeters
  const adjustHeight = 6.25 * inchesToCentimeters(heightInches);
  const adjustAge = 5 * age;
  let adjustGender = 0.0;
  let adjustActivity = 0.0;

  if (gender === Gender.MALE) {
    adjustGender = 5;
  } else {
    adjustGender = -161;
  }
  switch (atyLvl) {
    case ActivityLevel.SEDENTARY:
      adjustActivity = 1.2;
      break;
    case ActivityLevel.LIGHT:
      adjustActivity = 1.4;
      break;
    case ActivityLevel.MODERATE:
      adjustActivity = 1.6;
      break;
    case ActivityLevel.HEAVY:
      adjustActivity = 1.8;
      break;
    case ActivityLevel.EXTREME:
      adjustActivity = 1.95;
      break;
    default:
      break;
  }
  let calories = ceil(
    (adjustWeight + adjustHeight - adjustAge + adjustGender) * adjustActivity,
  );

  if (gender === Gender.NURSING_PART_TIME) {
    calories += 300;
  }
  if (gender === Gender.NURSING_FULL_TIME) {
    calories += 500;
  }
  calories = max(1200, calories);

  const daysinaweek = 7.0;
  switch (fitnessGoal) {
    case FitnessGoal.MAINTAIN:
      break;
    case FitnessGoal.LOSE_HALF_LBS_PER_WEEK:
      calories -= (0.5 * CALORIES_IN_POUND_FAT) / daysinaweek;
      break;
    case FitnessGoal.LOSE_ONE_LBS_PER_WEEK:
      calories -= CALORIES_IN_POUND_FAT / daysinaweek;
      break;
    case FitnessGoal.LOSE_ONE_AND_HALF_LBS_PER_WEEK:
      calories -= (1.5 * CALORIES_IN_POUND_FAT) / daysinaweek;
      break;
    case FitnessGoal.LOSE_TWO_LBS_PER_WEEK:
      calories -= (2 * CALORIES_IN_POUND_FAT) / daysinaweek;
      break;
    case FitnessGoal.GAIN_HALF_PER_WEEK:
      calories += (0.5 * CALORIES_IN_POUND_FAT) / daysinaweek;
      break;
    case FitnessGoal.GAIN_ONE_PER_WEEK:
      calories += CALORIES_IN_POUND_FAT / daysinaweek;
      break;
    default:
      break;
  }

  calories = max(1200, calories);

  return calories;
};

export const calculateDailyAllowanceForKeto = (
  gender,
  weightLbs,
  heightInches,
  age,
  atyLvl,
  fitnessGoal,
  carbsAllowancePercent,
) => {
  const caloriesAllowance = calculateDailyAllowanceForCalories(
    gender,
    weightLbs,
    heightInches,
    age,
    atyLvl,
    fitnessGoal,
  );
  const daily =
    (caloriesAllowance * Number(carbsAllowancePercent)) / 100.0 / 4.0;

  return daily;
};

export const calculateWeeklyAllowanceSmartPoints = (
  gender,
  weightLbs,
  heightInches,
  age,
) => {
  let weekly;
  const fage = age;
  if (Number(gender) !== 1) {
    //female
    const mw = weightLbs / 42.0 - fage / 39.0 + heightInches / 12.0 - 3.4332;
    weekly = min(max(round(mw), 3), 6) * 7.0;
  } else {
    //male
    const mw = weightLbs / 43.0 - fage / 39.0 + heightInches / 12.0 - 2.5;
    weekly = max(min(round(mw), 6), 3) * 7.0;
  }
  return weekly;
};

export const weeklyAllowancePointsForPlan = (user: User) => {
  let weekly = user.weeklyAllowance;
  const lossPlan = user.lossPlan;
  const weight =
    user.recentWeight && user.recentWeight > 0
      ? user.recentWeight
      : user.startWeightLbs;
  if (Number(user.isManualAllowance) !== 1) {
    if (lossPlan === LossPlan.CONQUER_CRAVINGS) {
      weekly = 35.0;
    } else if (lossPlan === LossPlan.CARB_CONSCIOUS) {
      weekly = 49.0;
    } else if (
      lossPlan === LossPlan.SUGAR_SMART ||
      lossPlan === LossPlan.BETTER_BALANCE
    ) {
      weekly = calculateWeeklyAllowanceSmartPoints(
        user.gender,
        weight,
        user.heightInch,
        user.age,
      );
    } else if (
      lossPlan === LossPlan.CALORIE_COMMAND ||
      lossPlan === LossPlan.KEEPING_KETO
    ) {
      weekly = 0;
    }
  }
  return weekly;
};

export const activityAllowancePointsForPlan = (user) => {
  let activityPts = 0.0;
  const method = Number(user.lossPlan);
  if (method === 0) {
    activityPts = 28.0;
  } else if (method === 1) {
    activityPts = 42.0;
  } else if (method === 3) {
    activityPts = 42.0;
  } else if (method === 4) {
    activityPts = 42.0;
  } else if (method === 2 || method === 5) {
    activityPts = user.activityAllowance;
  }
  return activityPts;
};

export const caloriesAllowancePoints = (user) => {
  let weight;
  const recentWeight = Number(user.recentWeight);
  if (recentWeight && recentWeight !== 0) {
    weight = recentWeight;
  } else {
    weight = user.startWeightLbs;
  }
  const daily = calculateDailyAllowanceForCalories(
    user.gender,
    weight,
    user.heightInch,
    user.age,
    user.activityLevel,
    user.fitnessGoal,
  );
  return daily;
};

export const heightCategoryFromInches = (inches: number) => {
  let ret = HeightCategory.UNDER_FIVEONE;
  if (inches < 61.0) {
    ret = HeightCategory.UNDER_FIVEONE;
  } else if (inches >= 61.0 && inches <= 70.0) {
    ret = HeightCategory.FIVEONE_TO_FIVETEN;
  } else if (inches > 70.0) {
    ret = HeightCategory.OVER_FIVETEN;
  }
  return ret;
};

export const stonesToLbs = (stones) => {
  return stones * LBS_IN_STONE;
};

export const kgsToLbs = (kgs) => {
  return kgs * LBS_IN_KG;
};

export const lbsToKgs = (lbs) => {
  return lbs / LBS_IN_KG;
};

export const inchesToCentimeters = (inches) => {
  return inches * CENTIMETERS_IN_INCH;
};

export const calculateFirstDayOfTheWeek = (dateTime, startDay) => {
  const currentDayofWeek = getDay(dateTime);
  let targetDayOfWeek = startDay;
  if (startDay === 0) {
    targetDayOfWeek = 7;
  }
  if (currentDayofWeek >= targetDayOfWeek) {
    return addDays(dateTime, targetDayOfWeek - currentDayofWeek).valueOf();
  } else {
    return addDays(dateTime, targetDayOfWeek - currentDayofWeek - 7);
  }
};

export const getSimplifiedWeight = (rawWeight, weightUnit) => {
  let value = rawWeight;
  if (+weightUnit === WeightUnit.KILOGRAMS) {
    value = lbsToKgs(rawWeight);
  }
  return toFixed(value, 1);
};

// export const caloriesNeededForGender = (
//   gender,
//   heightInches,
//   weightLbs,
//   age,
//   activityfactor,
// ) => {
//   const centimeters = inchesToCentimeters(heightInches);
//   const kilograms = lbsToKgs(weightLbs);

//   let adjustWeight = 0.0;
//   let adjustHeight = 0.0;
//   let adjustAge = 0.0;

//   if (
//     gender === Gender.FEMALE ||
//     gender === Gender.NURSING ||
//     gender === Gender.NURSING_PARTTIME
//   ) {
//     adjustWeight = 655 + 9.6 * kilograms;
//     adjustHeight = 1.7 * centimeters;
//     adjustAge = 4.7 * age;
//   } else if (gender === Gender.MALE) {
//     adjustWeight = 66 + 13.7 * kilograms;
//     adjustHeight = 5 * centimeters;
//     adjustAge = 6.8 * age;
//   }

//   let calories = ceil(
//     (adjustWeight + adjustHeight - adjustAge) * activityfactor +
//       (gender === Gender.NURSING || gender === Gender.NURSING_PARTTIME
//         ? 500.0
//         : 0.0),
//   );
//   if (calories < 1200) {
//     calories = 1200;
//   }
//   return calories;
// };

// export const caloriesAdjustedForFitnessGoal = (fitnessgoal, calories) => {
//   let daysinaweek = 7.0;
//   if (fitnessgoal === FITNESSGOAL_MAINTAIN) {
//   } else if (fitnessgoal === FITNESSGOAL_LOSE_HALFPOUND_WEEK) {
//     calories = calories - CALORIES_IN_POUND_FAT / 2.0 / daysinaweek;
//   } else if (fitnessgoal === FITNESSGOAL_LOSE_ONEPOUND_WEEK) {
//     calories = calories - CALORIES_IN_POUND_FAT / daysinaweek;
//   } else if (fitnessgoal === FITNESSGOAL_LOSE_ONEANDHALFPOUND_WEEK) {
//     calories =
//       calories -
//       (CALORIES_IN_POUND_FAT + CALORIES_IN_POUND_FAT / 2.0) / daysinaweek;
//   } else if (fitnessgoal === FITNESSGOAL_LOSE_TWOPOUND_WEEK) {
//     calories =
//       calories - (CALORIES_IN_POUND_FAT + CALORIES_IN_POUND_FAT) / daysinaweek;
//   } else if (fitnessgoal === FITNESSGOAL_GAIN_HALFPOUND_WEEK) {
//     calories = calories + CALORIES_IN_POUND_FAT / 2.0 / daysinaweek;
//   } else if (fitnessgoal === FITNESSGOAL_GAIN_POUND_WEEK) {
//     calories = calories + CALORIES_IN_POUND_FAT / daysinaweek;
//   }

//   if (calories < 1200) {
//     calories = 1200;
//   }
//   return calories;
// };

export const lbsToString = (lbs, weightUnit, round) => {
  let ret = "";
  if (weightUnit === WeightUnit.POUNDS) {
    ret = `${round ? toFixed(toFixed(lbs, 0), 1) : toFixed(lbs, 1)} lbs`;
  } else if (weightUnit === WeightUnit.KILOGRAMS) {
    ret = `${
      round ? toFixed(toFixed(lbsToKgs(lbs), 0), 1) : toFixed(lbsToKgs(lbs), 1)
    } kg`;
  } else if (weightUnit === WeightUnit.STONES) {
    const stonelbs = getStoneAndPoundsFromLbs(lbs);
    ret = `${stonelbs[0]} st ${stonelbs[1]} lbs`;
  }
  return ret;
};

export const getStoneAndPoundsFromLbs = (weightLbs: number) => {
  const stones = lbsToStones(weightLbs) | 0;
  const pounds = weightLbs % LBS_IN_STONE;
  return [stones, pounds];
};

export const lbsToStones = (lbs: number) => {
  return lbs / LBS_IN_STONE;
};

export const fatAllowance = (user) => {
  return (
    (Number(user.caloriesAllowance) * Number(user.fatAllowancePercent)) /
    100 /
    9
  );
};

export const carbsAllowance = (user) => {
  return (
    (Number(user.caloriesAllowance) * Number(user.carbsAllowancePercent)) /
    100 /
    4
  );
};

export const proteinAllowance = (user) => {
  return (
    (Number(user.caloriesAllowance) * Number(user.proteinAllowancePercent)) /
    100 /
    4
  );
};

export const inchesToString = (inches, heightUnit) => {
  let ret = "";
  if (+heightUnit === HeightUnit.INCHES) {
    ret = toFixed(inches, 1) + " inches";
  } else if (+heightUnit === HeightUnit.CENTIMETERS) {
    ret = toFixed(inchesToCentimeters(inches), 1) + " cm";
  } else if (+heightUnit === HeightUnit.FEET_AND_INCHES) {
    let feet = 0;
    let inch = 0;
    const feetInches = getFeetAndInches(inchesToCentimeters(inches));
    if (feetInches.length === 2) {
      feet = feetInches[0];
      inch = feetInches[1];
    }
    ret = `${feet} ft ${inch} in`;
  }
  return ret;
};

export const getFeetAndInches = (centimeters) => {
  const totalInches = centimetersToInches(centimeters);
  const feet = (totalInches / INCHES_IN_FOOT) | 0;
  const inches = round(totalInches) % INCHES_IN_FOOT;
  return [feet, inches];
};

export const centimetersToInches = (centimeters) => {
  return centimeters / CENTIMETERS_IN_INCH;
};

export const inchesToFeet = (inches) => {
  return inches / INCHES_IN_FOOT;
};

export const feetToInches = (feet) => {
  return feet * INCHES_IN_FOOT;
};

export const caloriesBurnedForWeightLbs = (lbs, met, mins) => {
  return (mins * (met * 3.5 * lbsToKgs(lbs))) / 200;
};

export const feetInchesStringToInches = (ftIn: string) => {
  const result = /(\d{1,2})[^\d]*(\d{1,2})?[^\d]*/.exec(ftIn);
  if (result) {
    const [, feet = "", inch = ""] = result;
    return feetToInches(Number(feet)) + Number(inch);
  }
  return 0;
};

export const stoneStringToLbs = (stongStr: string) => {
  const result = /(\d{1,2})[^\d]*(\d{1,2})?[^\d]*/.exec(stongStr);
  if (result) {
    const [, st = "", lbs = ""] = result;
    return stonesToLbs(Number(st)) + Number(lbs);
  }
  return 0;
};

export const getInches = (value: string | number, unit: number) => {
  if (unit === HeightUnit.CENTIMETERS) {
    return centimetersToInches(value);
  } else if (unit === HeightUnit.FEET_AND_INCHES) {
    return feetInchesStringToInches(String(value));
  }
  return Number(value);
};

export const getLbs = (value: string | number, unit: number) => {
  if (unit === WeightUnit.STONES) {
    return stoneStringToLbs(String(value));
  } else if (unit === WeightUnit.KILOGRAMS) {
    return kgsToLbs(value);
  }
  return Number(value);
};

export const inchesToUnit = (inches, unit, round) => {
  if (unit === HeightUnit.CENTIMETERS) {
    return !isUndefined(round)
      ? toFixed(inchesToCentimeters(inches), round)
      : inchesToCentimeters(inches);
  } else if (unit === HeightUnit.FEET_AND_INCHES) {
    const [foot, inch] = getFeetAndInches(inchesToCentimeters(inches));
    return `${foot}'${inch}"`;
  }
  return !isUndefined(round) ? toFixed(Number(inches), round) : Number(inches);
};

export const lbsToUnit = (lbs, unit, round?) => {
  if (unit === WeightUnit.STONES) {
    return lbsToString(lbs, WeightUnit.STONES, 0);
  } else if (unit === WeightUnit.KILOGRAMS) {
    return !isUndefined(round) ? toFixed(lbsToKgs(lbs), round) : lbsToKgs(lbs);
  }
  return !isUndefined(round) ? toFixed(Number(lbs), round) : Number(lbs);
};
