// type GoogleProfile = {
//   idToken: string;
//   userId: string;
//   email: string;
//   name: string;
//   profilePhotoUrl: string;
// };

let googleAuth: gapi.auth2.GoogleAuth;

export const googleLogin = (): Promise<{ email: string; idToken: string }> => {
  return new Promise((resolve, reject) => {
    const signIn = () => {
      googleAuth.currentUser.listen((googleUser: gapi.auth2.GoogleUser) => {
        if (!googleAuth.isSignedIn.get()) return;
        try {
          const basicProfile = googleUser.getBasicProfile();
          const profile = {
            idToken: googleUser.getAuthResponse().id_token,
            userId: googleUser.getId(),
            email: basicProfile.getEmail(),
            name: basicProfile.getName(),
            profilePhotoUrl: basicProfile.getImageUrl(),
          };
          resolve(profile);
        } catch (error) {
          reject(error);
        }
      });
      googleAuth.signIn();
    };
    if (googleAuth) {
      signIn();
    } else {
      const gapi = window.gapi;
      if (!gapi) {
        return reject(0);
      }
      gapi.load("auth2", () => {
        gapi.auth2
          .init({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            client_id:
              "373179665993-9bb0n7ssneuh8otv4smui4ruegeaqt7o.apps.googleusercontent.com",
            scope: "profile email",
          })
          .then((g) => {
            googleAuth = g;
            signIn();
          });
      });
    }
  });
};
