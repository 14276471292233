import React, { ReactNode } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";

type Props = {
  loading?: boolean;
  children: ReactNode;
} & ButtonProps;

const LoadingButton: React.FC<Props> = (props) => {
  const { loading, children, ...rest } = props;
  const style: React.CSSProperties = {};
  if (loading) {
    style.pointerEvents = "none";
    style.opacity = 0.8;
  }
  return (
    <Button {...rest} style={style}>
      {loading ? (
        <CircularProgress
          style={{
            height: "24px",
            width: "24px",
          }}
          color="inherit"
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;
