import React, { ReactNode } from "react";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    padding: "8px 8px",
    color: "white",
  },
}));

const SnackBarProvider = ({ children }: { children?: ReactNode }) => {
  const classes = useStyles();
  const notistackRef = React.createRef();
  const onClickDismiss = (key: any) => () => {
    (notistackRef.current as any).closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key) => (
        <IconButton className={classes.closeIcon} onClick={onClickDismiss(key)}>
          <CloseIcon fontSize="small"></CloseIcon>
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SnackBarProvider;
