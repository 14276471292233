import React, { ReactNode, useState } from "react";
import TopBar from "./TopBar";
import Footer from "./Footer";
import { makeStyles, Grid, Drawer } from "@material-ui/core";
import Wrapper from "./Wrapper";
import LeftNav from "layouts/LeftNav";
import clsx from "clsx";

interface Props {
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
  },
  background: {
    height: 155,
    width: "100%",
    background: "none",
    backgroundSize: "cover",
    position: "absolute",
    zIndex: -1,
  },
}));

const MainLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <div className={clsx(classes.root, "main-layout")}>
      <TopBar type="main" setDrawerOpen={setDrawerOpen} />
      <Wrapper>
        <div className="main-container">
          <div
            className={clsx(classes.background, "main-container-page-bg")}
          ></div>
          <div className="main-content">
            <Grid
              spacing={6}
              container
              direction="row"
              wrap="nowrap"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Grid
                item
                style={{ height: "100%" }}
                className="left-page-container"
              >
                <LeftNav></LeftNav>
              </Grid>
              <React.Fragment>
                <Drawer
                  className="app-drawer"
                  anchor="left"
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                >
                  <LeftNav
                    handleBackClick={() => setDrawerOpen(false)}
                  ></LeftNav>
                </Drawer>
              </React.Fragment>
              <Grid className="right-page-container" item>
                {children}
              </Grid>
            </Grid>
          </div>
        </div>
        <Footer></Footer>
      </Wrapper>
    </div>
  );
};

export default MainLayout;
