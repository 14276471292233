import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function BasicBadge(props) {
  return (
    <SvgIcon
      width="56"
      height="24"
      viewBox="0 0 56 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="56" height="24" rx="4" fill="#F7F8FA" />
      <path
        d="M9 18.2886H14.0101C16.1869 18.2886 18.6056 17.4255 18.6056 14.8184C18.6056 13.1978 17.5344 11.9824 15.9278 11.771V11.7358C17.1889 11.3306 18.0355 10.3794 18.0355 8.98781C18.0355 6.27507 15.7032 5.81707 13.5609 5.81707H9V18.2886ZM11.6951 8.14228H13.5264C14.3729 8.14228 15.3404 8.4065 15.3404 9.4458C15.3404 10.4499 14.4766 10.8022 13.6473 10.8022H11.6951V8.14228ZM11.6951 13.1274H13.7337C14.6148 13.1274 15.9105 13.3211 15.9105 14.5014C15.9105 15.5935 14.9603 15.9634 14.1138 15.9634H11.6951V13.1274Z"
        fill="#323D47"
      />
      <path
        d="M24.6354 18.2886H27.0195V13.9377C27.0195 11.084 26.2076 9.51626 23.1842 9.51626C21.8194 9.51626 20.4373 9.97425 19.4871 10.8902L20.8692 12.3347C21.422 11.7005 22.1304 11.313 22.9769 11.313C23.8753 11.313 24.6354 11.9119 24.6354 12.8455C22.4759 12.8455 18.9515 12.8984 18.9515 15.8577C18.9515 17.584 20.3682 18.5 21.9403 18.5C22.9942 18.5 24.0307 18.1301 24.6009 17.2141H24.6354V18.2886ZM24.6354 14.5366V15.1003C24.6354 16.1572 23.6852 16.7033 22.6659 16.7033C22.0958 16.7033 21.4393 16.4038 21.4393 15.7696C21.4393 14.607 23.2015 14.5366 23.9962 14.5366H24.6354Z"
        fill="#323D47"
      />
      <path
        d="M34.4776 10.6436C33.7002 9.7981 32.4736 9.51626 31.3679 9.51626C29.6057 9.51626 27.8436 10.397 27.8436 12.4051C27.8436 15.664 32.2144 14.5014 32.2144 15.8753C32.2144 16.3333 31.6271 16.4919 31.0397 16.4919C30.2622 16.4919 29.6921 16.1572 29.2084 15.5583L27.6363 17.3374C28.4655 18.2005 29.7612 18.5 30.936 18.5C32.7327 18.5 34.8059 17.8659 34.8059 15.6816C34.8059 12.4228 30.435 13.4973 30.435 12.229C30.435 11.7182 30.9533 11.5244 31.3852 11.5244C32.0071 11.5244 32.5081 11.771 32.8882 12.2466L34.4776 10.6436Z"
        fill="#323D47"
      />
      <path
        d="M35.6607 18.2886H38.2521V9.72764H35.6607V18.2886ZM35.4534 7.03252C35.4534 7.87805 36.1271 8.56504 36.9564 8.56504C37.7857 8.56504 38.4594 7.87805 38.4594 7.03252C38.4594 6.18699 37.7857 5.5 36.9564 5.5C36.1271 5.5 35.4534 6.18699 35.4534 7.03252Z"
        fill="#323D47"
      />
      <path
        d="M47 10.6612C46.188 9.81572 44.996 9.51626 44.0112 9.51626C41.4198 9.51626 39.3466 11.2778 39.3466 14.0081C39.3466 16.7385 41.4198 18.5 44.0112 18.5C44.996 18.5 46.188 18.2005 47 17.355L45.2896 15.523C44.996 15.8753 44.5813 16.1748 44.0112 16.1748C42.7155 16.1748 41.9381 15.294 41.9381 14.0081C41.9381 12.7222 42.7155 11.8415 44.0112 11.8415C44.5468 11.8415 45.0305 12.1233 45.2896 12.4932L47 10.6612Z"
        fill="#323D47"
      />
    </SvgIcon>
  );
}
